//
// MMenu
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */

// # Variables
// =============================================== */

$mmocd_width: 80% !default;
$mmocd_min_width: 200px !default;
$mmocd_max_width: 440px !default;
$mmocd_transition_duration: 0.3s !default;

$mmspn_transition_duration: 0.3s;
$mmspn_arrow_size: 10px !default;
$mmspn_arrow_weight: 2px !default;
$mmspn_border_opac: 0.15 !default;
$mmspn_navbar_opac: 0.4 !default;
$mmspn_arrow_opac: 0.4 !default;
$mmspn_item_height: 50px !default;
$mmspn_item_indent: 20px !default;
$mmspn_line_height: 24px !default;
$mmspn_panel_offset: 30% !default;

// # Imports
// =============================================== */
@import '~mmenu-light/src/mmenu-light';

// # Overrides
// =============================================== */

.mm-ocd {
	font-family: $font-family-base;
}

.mm-spn {
	//  Light theme
	&.mm-spn--light {
		color: #444;
		background: #f3f3f3;
	}

	//  Dark theme
	&--dark {
		color: #ddd;
		background: #333;
	}
}
