// Table of Content
// ===============================================
// # Welcome
// # Variables
// # Base
// # Critical
// # Fonts
// # Plugins
// # Vendors
//
// # Welcome
//   This file is used to centralize the imports
// =============================================== */
//
// Most of the following guidelines used to write css:
// http://cssguidelin.es
//
// # Variables
//   Globally-available variables and configs
// =============================================== */
$fonts-path: 'assets/fonts';

@import 'variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/bootstrap';


/**
"domainStyle" is a reference to the generated scss file inside the domain folder.
It is aliased in frontend/frontend-nwn/webpack.config.js.
It consists of bootstrap variables, global- and section-styling.
 */
@import "domainStyle"; // Ignore the warnings. Its an alias to the domain set by webpack.


//
// # Base
// Resets, grids, wrappers, body, typography and forms
// =============================================== */

//@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/buttons';
@import 'base/reset';
@import 'base/base';

//
// # Critical
//   The critical components to load inline
// =============================================== */

@import 'global';
@import 'leaflet';
@import 'unused';

//
// # Fonts
//   Load custom fonts
// =============================================== */

@import '../fonts/nwn-icons/icons';
@import '../fonts/public-sans/public-sans';

//
// # Plugins
//   Apply theme styling to plugins
// =============================================== */
//@import 'plugins/**/*.scss';

//
// # Vendors
//   Vendor includes
// =============================================== */

@import 'vendor/magnific-popup/magnific-popup';
@import 'vendor/mmenu/mmenu';
@import 'vendor/slick/slick';
