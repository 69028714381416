//
// Slick slider
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */

// # Variables
// =============================================== */

// # Imports
// =============================================== */
@import '~slick-carousel/slick/slick';

// # Overrides
// =============================================== */
.slick-list {
	.specialists-form &,
	.solutions-slider &,
	.advisors-section &,
	.specialists-slider &,
	.usp-slider &,
	.project-slider & {
		overflow: visible;
	}
}
