@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-Regular.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-SemiBoldItalic.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-Italic.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-Light.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-BoldItalic.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-Bold.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-LightItalic.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Public Sans';
	src: url('#{$fonts-path}/public-sans/PublicSans-SemiBold.woff2') format('woff2'), url('#{$fonts-path}/public-sans/PublicSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

