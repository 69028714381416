.advisors-section {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  h2 {
    strong {
      display: block;
      color: $pink;
    }
  }

  &__list {
    margin: 0 -8px;
    padding: 0;
    width: calc(100% + 16px);
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item {
    padding: 0 8px 16px;
    position: relative;
    width: 255px;
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
    @include media-breakpoint-up(xl) {
      width: 33.333%;
    }
  }

  &__logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
    border-radius: 4px;
    background: $gray-300;
  }
}

.banner-onboarding {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    padding: 22px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 15px;
    background: rgba($gray-300, 0.8);
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 60%;
    }

    p {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }

  &__right {
    margin-top: 30px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-right: 15px;
      display: flex;
      justify-content: flex-end;
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 25px;
    }

    .btn {
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }

  &__bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    @include media-breakpoint-up(lg) {
      width: 310px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__line {
    margin-top: 3px;
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: rgba($pink, 0.2);
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__line-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    background: $cyan;
  }

  &__image {
    padding-right: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 96px;

    img {
      max-height: 60px;
    }
  }

  &__text {
    width: 100%;
    font-size: $font-size-sm;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 122px);
    }
    @include media-breakpoint-up(xl) {
      padding-left: 50px;
    }

    p {
      @include media-breakpoint-up(lg) {
        margin-bottom: 10px;
      }
    }
  }
}

.breadcrumb {
  margin: 0 0 15px;
  padding: 0;
  position: relative;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: $teal;
  background: transparent;
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;
    color: $pink;
  }

  .breadcrumb-list {
    padding: 0;
  }

  &--dark {
    padding-top: 20px;
    border-top: 1px solid $border-color;

    .breadcrumb-list {
      margin: 0;
    }
  }

  .breadcrumb-item {
    display: inline-flex;
    align-items: center;
    color: $teal;

    a {
      color: $teal;

      &:hover {
        color: $white;
      }
    }

    + .breadcrumb-item {
      &:before {
        font-family: $font-family-icon;
        font-size: 10px;
        line-height: 1.2;
        color: $teal;
        content: '\e90b';
        @include media-breakpoint-up(lg) {
          margin: 0 5px;
        }
      }
    }
  }
}

.card-blockquote {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid $gray-500;
  border-bottom: 1px solid $gray-500;
  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__name {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__name-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 12px;
    color: $white;
    border-radius: 50%;
    background: $cyan;
  }

  &__name-text {
    padding-left: 20px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      color: $teal;
    }

    strong {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $blue;
    }
  }
}

.card-price {
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(md) {
    padding: 25px 50px;
  }
  @include media-breakpoint-up(xl) {
    padding: 50px 130px;
  }
}

.card-privacy {
  margin-bottom: 10px;
  padding: 0 20px;
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: $white;
  @include media-breakpoint-up(lg) {
    padding: 0 40px 0 30px;
  }

  &.is-active {
    .card-privacy__link {
      &:after {
        background: $cyan;
      }

      &:before {
        right: 2px;
      }
    }
  }

  &__link {
    padding: 18px 60px 18px 0;
    position: relative;
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: $blue;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 20px;
    }

    span {
      padding-top: 5px;
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      @include media-breakpoint-up(lg) {
        padding-top: 10px;
        font-size: 16px;
        font-weight: 300;
        color: $pink;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 53px;
      height: 24px;
      border-radius: 40px;
      background: $gray-500;
      transform: translateY(-50%);
      transition: all 0.3s;
      content: '';
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 31px;
      z-index: 1;
      width: 20px;
      height: 20px;
      border-radius: 40px;
      background: $white;
      transform: translateY(-50%);
      transition: all 0.3s;
      content: '';
      cursor: pointer;
    }
  }

  &__panel {
    display: none;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      max-height: inherit;
      overflow: visible;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      padding: 30px;
      border: 1px solid rgba($pink, 0.25);
      border-radius: 8px;
    }
  }
}

.card-search-expert {
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(xl) {
    padding: 60px;
  }

  h4 {
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .form-group {
    width: 50%;

    label {
      @include media-breakpoint-up(lg) {
        padding-top: 21px;
      }
    }

    .form-control {
      @include media-breakpoint-up(lg) {
        height: 64px;
      }
    }

    &:nth-child(1) {
      @include media-breakpoint-up(md) {
        width: 290px;
      }

      .form-control {
        border-radius: 8px 0 0 8px;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        width: 137px;
      }

      .form-control {
        margin-left: -1px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
}

.card-step {
  margin-bottom: 16px;
  padding-bottom: 35px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }

  h6 {
    margin-bottom: 20px;
  }

  &__img {
    margin-bottom: 25px;
    padding: 10px 10px 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    @include background-image(step-block-bg, svg, center top, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      padding: 0;
      height: 144px;
    }

    img {
      max-height: 100%;
      object-fit: contain;
    }

    .step-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px;
      position: absolute;
      height: 80px;
      width: 80px;
    }
  }

  &__icon {
    padding-top: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
  }
}

.card-video {
  position: relative;
  display: block;
  width: 100%;

  &__block {
    position: relative;
    display: block;
    border-radius: 8px;
    overflow: hidden;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 60%);
      content: '';
    }

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  &__btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 2;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    @include media-breakpoint-up(xl) {
      bottom: 40px;
      left: 40px;
    }

    i {
      margin-right: 15px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      color: $cyan;
      border-radius: 50%;
      background: $white;
    }
  }
}

.contact-info {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__image {
    @include media-breakpoint-up(xl) {
      position: absolute;
      right: 20px;
      bottom: 0;
      width: 130px;
    }
    @include media-breakpoint-up(xxl) {
      right: 40px;
      width: 180px;
    }
    @include media-breakpoint-up(xxxl) {
      right: 80px;
      width: 206px;
    }
  }

  &__content {
    margin-bottom: 15px;
    padding: 20px;
    position: relative;
    border-radius: 16px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding: 30px;
      min-height: 100%;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 170px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 60px 250px 60px 60px;
    }
    @include media-breakpoint-up(xxxl) {
      padding: 60px 300px 60px 80px;
    }

    h4 {
      strong {
        display: inline-block;
        color: $pink;
      }
    }

    p {
      margin-bottom: 16px;
    }
  }

  &__form {
    padding: 20px;
    border-radius: 16px;
    background-color: $blue;
    @include background-image(contact-info-bg, svg, center right -40px, no-repeat);
    @include media-breakpoint-up(lg) {
      padding: 30px;
      min-height: 100%;
    }
    @include media-breakpoint-up(xxl) {
      padding: 60px;
    }
    @include media-breakpoint-up(xxxl) {
      padding: 60px 120px 60px 80px;
    }

    h4 {
      color: $white;
    }

    p {
      margin-bottom: 16px;
      color: rgba($white, 0.5);
    }

    .form-check {
      .form-check-input {
        &:checked + .form-check-label {
          &:after {
            background-color: $cyan;
          }
        }
      }
    }
  }

  &__bottom {
    margin-bottom: 25px;
    position: relative;
    @include media-breakpoint-up(lg) {
      padding-right: 75px;
    }

    .btn {
      margin-top: 20px;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.form-toggle {
  margin-bottom: 10px;
  padding: 0 20px 1px;
  position: relative;
  width: 100%;
  border-radius: 8px;
  background: $white;
  @include media-breakpoint-up(lg) {
    padding: 0 30px 1px;
    border-radius: 16px;
  }

  &.is-active {
    margin-bottom: 30px;

    .form-toggle__link {
      &:after {
        background: $cyan;
      }

      &:before {
        right: 2px;
      }
    }
  }

  &__link {
    padding: 20px 0;
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: $blue;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
      padding: 30px 0;
      font-size: 18px;
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 53px;
      height: 24px;
      border-radius: 40px;
      background: $gray-500;
      transform: translateY(-50%);
      transition: all 0.3s;
      content: '';
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 31px;
      z-index: 1;
      width: 20px;
      height: 20px;
      border-radius: 40px;
      background: $white;
      transform: translateY(-50%);
      transition: all 0.3s;
      content: '';
      cursor: pointer;
    }
  }

  &__panel {
    display: none;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      max-height: inherit;
      overflow: visible;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      padding: 30px;
      border: 1px solid rgba($pink, 0.25);
      border-radius: 8px;
    }
  }
}

.gallery-banner {
  margin-bottom: 24px;
  padding: 165px 20px 0;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 240px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 1840px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      border-radius: 16px;
    }
  }

  &__large {
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 50%;
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 0;
      }
    }
  }

  &__small-item {
    padding: 0 4px;
    width: 33.333%;
    height: 80px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
      width: 50%;
    }
  }

  &__small {
    margin: 0 -4px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin: 0;
      width: 50%;
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 0;
      }
    }
  }

  &__button {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 0 20px;
      top: 30px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: $cyan;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;
    @include media-breakpoint-up(lg) {
      width: 48px;
      height: 48px;
    }

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;
        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;
          }
        }
      }
    }
  }
}

.house-content {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  h3 {
    margin-bottom: 8px;
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: -1px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 20px;
      font-size: $h3-font-size;
      line-height: 48px;
    }
  }

  &__tag {
    margin-bottom: 24px;

    span {
      padding: 3px 8px;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 2px;
      background: $cyan;
    }
  }

  &__price {
    margin-bottom: 8px;
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      padding-bottom: 27px;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }

    h5 {
      margin-right: 30px;
      margin-bottom: 0;
      color: $blue;
    }

    span {
      display: inline-flex;
      align-items: center;
      font-weight: 300;
      color: $teal;

      i {
        margin-right: 10px;
        font-size: 14px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }

    strong {
      margin-right: 20px;
    }

    span {
      padding: 3px 8px;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 2px;
      background: $green;
    }
  }

  &__list {
    padding: 0 0 25px;
    width: 100%;
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }
  }

  &__list-item {
    margin-right: 10px;
    padding: 5px 5px 5px 25px;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid $border-color;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0;
    }

    p {
      margin-bottom: 0;
    }

    i {
      position: absolute;
      top: 7px;
      left: 0;
      color: $cyan;
    }

    strong {
      font-weight: 600;
      color: $blue;
    }
  }
}

.house-features {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__subtitle {
    font-weight: 600;
    color: $blue;
  }

  &__item {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    border-bottom: 1px solid $gray-500;

    &:last-child {
      border-bottom: 0;
    }

    span {
      width: 50%;
      color: $pink;
    }

    strong {
      width: 50%;
      font-weight: 400;
      color: $blue;
    }
  }

  &__list {
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
    @include media-breakpoint-up(xl) {
      margin-bottom: 30px;
    }
  }
}

.house-object {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      border-radius: 5px;
      @include media-breakpoint-up(lg) {
        padding-top: 5px;
      }

      li {
        &:first-child {
          a {
            border-left: 1px solid rgba($pink, 0.25);
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          a {
            border-radius: 0 5px 5px 0;
          }
        }

        a {
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 32px;
          font-size: 14px;
          font-weight: 600;
          color: $blue;
          border-top: 1px solid rgba($pink, 0.25);
          border-right: 1px solid rgba($pink, 0.25);
          border-bottom: 1px solid rgba($pink, 0.25);

          &:hover {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }

        &.is-active {
          a {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }
      }
    }
  }

  &__heading {
    padding: 8px 30px 8px 15px;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: $white;
    border-radius: 5px;
    background: $blue;
    @include media-breakpoint-up(xl) {
      margin-bottom: 10px;
      padding-left: 5px;
    }

    strong {
      font-weight: 600;
    }
  }

  &__tag {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: $gray-300;

      &:before {
        content: 'Status';
      }
    }

    span {
      padding: 3px 8px;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 2px;
      background: $green;
    }

    &--sold {
      span {
        background: $red;
      }
    }

    &--offer {
      span {
        background: $orange;
      }
    }
  }

  &__list {
    margin-top: 25px;
  }

  &__item {
    position: relative;
    z-index: 1;
    width: 100%;

    &.is-active {
      border-radius: 4px;

      .house-object__item-panel {
        @include media-breakpoint-up(md) {
          border-radius: 4px;
          background: $gray-300;
        }

        &:after {
          @include media-breakpoint-up(md) {
            content: '\e90c';
          }
        }
      }

      .house-object__item-title {
        &:after {
          top: 9px;
          font-size: 1.5px;
          content: '\e926';
        }
      }

      .house-object__item-block {
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }

  &__item-panel {
    padding: 15px 0 0;
    position: relative;
    display: none;
    font-size: 16px;
    color: $blue;
    @include media-breakpoint-up(md) {
      margin: 0 !important;
      padding: 15px 30px 15px 0 !important;
      display: block !important;
      height: auto !important;
      border-bottom: 1px solid $border-color;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 5px;
    }

    &:after {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 50%;
        right: 10px;
        font-family: $font-family-icon;
        font-size: 8px;
        transform: translateY(-50%);
        content: '\e90a';
      }
      @include media-breakpoint-up(xl) {
        right: 20px;
      }
    }
  }

  &__item-block {
    margin-top: 20px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    display: none;
    border-bottom: 1px solid $border-color;
  }

  &__item-title {
    padding-bottom: 10px;
    position: relative;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 55px;
      cursor: pointer;
    }

    &:after {
      position: absolute;
      top: 2px;
      right: 0;
      font-family: $font-family-icon;
      font-size: 12px;
      content: '\e928';
    }
  }

  &__title {
    &:before {
      @include media-breakpoint-down(sm) {
        content: '';
      }
    }
  }

  &__wonen {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;

      &:before {
        content: 'Wonen';
      }
    }
  }

  &__room {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: $gray-300;

      &:before {
        content: 'Kamers';
      }
    }
  }

  &__price {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;

      &:before {
        content: 'Prijs';
      }
    }
  }

  &__block-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 12px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(md) {
      display: inline-block;
      width: auto;
      height: auto;
      border-radius: 0;
      background: transparent;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__block {
    margin: 20px auto 0;
    position: relative;
    z-index: 2;
    max-width: 720px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin-top: -20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 25px;
    }

    .card-house-list__image {
      @include media-breakpoint-up(xl) {
        padding-right: 32px;
      }
    }

    .card-house-list__list {
      padding: 5px 0 10px;
    }

    .card-house-list__content {
      padding-left: 20px;
      @include media-breakpoint-up(xl) {
        padding-left: 0;
      }
    }
  }
}

.message-popup {
  width: 100%;
}

.modal {
  width: 100%;
}

.not-found {
  margin-top: 160px;
  margin-bottom: 40px;
  padding-top: 45px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  text-align: center;
  color: rgba($white, 0.6);
  border-radius: 16px;
  background-color: $blue;
  @include background-image(not-found-bg2, svg, left top, no-repeat, cover);
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: 120px;
    padding-top: 220px;
    padding-bottom: 80px;
    border-radius: 0 0 15px 15px;
    background-position: center top;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 280px;
    padding-bottom: 135px;
  }

  h1 {
    margin-bottom: 20px;
    color: $white;
    @include media-breakpoint-up(xl) {
      margin-bottom: 45px;
    }
  }

  p {
    margin-bottom: 30px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }
  }

  &__image {
    margin: 0 auto 30px;
    padding-right: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 88px;
    height: 96px;
    @include background-image(not-found-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      width: 120px;
      height: 131px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 70px;
    }

    img {
      width: 40px;
      @include media-breakpoint-up(lg) {
        width: 50px;
      }
    }
  }
}

.offer-banner {
  padding-top: 130px;
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
  width: 100%;
  color: $white;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    padding-bottom: 90px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 240px;
    padding-bottom: 125px;
  }

  &__text {
    font-size: $font-size-base;
    font-weight: 300;
    @include media-breakpoint-up(md) {
      font-size: $font-size-lg;
    }

    strong {
      font-weight: 700;
    }
  }

  h1 {
    margin-bottom: 15px;
    color: $white;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    border-radius: 0 0 16px 16px;
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 125px);
    }
  }
}

// begin onboarding-popup
.onboarding-popup {
  width: 100%;
}

.housingneeds-popup {
  .mfp-close {
    display: none;
  }
}

.mfp-fullscreen {
  .mfp-container {
    padding: 0; /* 1 */
  }
  .mfp-content {
    height: 100%; /* 2 */
  }
}

.full-popup {
  background: #FFF;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
}
// end onboarding-popup

.pre-footer {
  position: relative;
  width: 100%;
  border-top: 1px solid $light-border;
  border-bottom: 1px solid $light-border;

  &__holder {
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 35px 0;
    }
  }

  &__left {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 70%;
    }

    h6 {
      margin-bottom: 25px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    strong {
      font-weight: 600;
      color: rgba($pink, 1);
    }
  }

  &__right {
    margin: 0 -5px;
    display: inline-flex;
    justify-content: flex-end;
    width: calc(100% + 10px);
    @include media-breakpoint-up(lg) {
      margin: 0;
      width: 30%;
    }

    .btn {
      margin: 0 5px;
      width: calc(50% - 10px);
      @include media-breakpoint-up(lg) {
        margin: 0 0 0 15px;
        width: auto;
      }
    }
  }
}

.preloader-block {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: $blue;

  img {
    display: block;
    width: 289px;
    height: auto;
  }
}

.search-heading {
  margin-bottom: 80px;
  padding-top: 130px;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  background: $blue;
  @include media-breakpoint-up(lg) {
    margin-bottom: 90px;
    padding-top: 160px;
    padding-bottom: 65px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 185px;
  }

  &__holder {
    width: 100%;
    @include media-breakpoint-up(lg) {
      text-align: center;
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: rgba($white, 0.6);
    }
  }

  &__search {
    padding: 0 40px 0 20px;
    position: absolute;
    bottom: -36px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    @include media-breakpoint-up(lg) {
      padding-right: 20px;
      width: 550px;
    }
    @include media-breakpoint-up(xl) {
      bottom: -40px;
      width: 800px;
    }
  }

  &__search-inner {
    padding-left: 80px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 72px;
    border-radius: 16px 0 0 16px;
    background: $white;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(xl) {
      padding-left: 100px;
      height: 80px;
    }

    label {
      margin-bottom: 0;
      padding-right: 22px;
      padding-left: 20px;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-flex;
      align-items: center;
      height: 72px;
      font-size: $font-size-sm;
      font-weight: 400;
      color: $blue;
      @include background-image(arrow, svg, center right, no-repeat);
      @include media-breakpoint-up(xl) {
        padding-left: 34px;
        height: 80px;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-color: transparent transparent transparent $white;
      border-style: solid;
      border-width: 36px 0 36px 18px;
      content: '';
      @include media-breakpoint-up(xl) {
        border-width: 40px 0 40px 24px;
      }
    }
  }

  &__search-link {
    padding: 0 35px 0 15px;
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-size: 12px;
    font-weight: 600;
    color: $white;
    border-radius: 5px;
    background: $cyan;

    &:hover {
      text-decoration: none;
      color: $white;
    }

    i {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 10px;
      font-weight: 400;
    }
  }

  &__search-btn {
    position: absolute;
    top: 13px;
    right: 2px;
    width: 48px;
    height: 48px;
    font-size: $font-size-sm;
    color: $white;
    border: 0;
    border-radius: 50%;
    background: $orange;
    @include media-breakpoint-up(xl) {
      top: 16px;
      right: 5px;
    }
  }
}

.search-list {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__list {
    margin: 0 -8px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
  }

  &__list-item {
    padding: 9px 9px 25px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
    @include media-breakpoint-up(xl) {
      width: 33.333%;
    }
  }
}

.solutions-slider {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__top {
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
      text-align: center;
    }

    h2 {
      margin-bottom: 30px;

      strong {
        color: $pink;
      }
    }

    p {
      strong {
        font-weight: 600;
        color: $blue;
      }
    }
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__slide {
    margin-right: 28px;
    margin-bottom: 20px;
    width: 256px;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      width: 400px;
    }
    @include media-breakpoint-up(xxl) {
      width: 530px;
    }
  }

  &__block {
    padding: 20px;
    font-size: 16px;
    color: $blue;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      padding: 50px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.sticky-bar {
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: $white;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__left-img {
    display: flex;
    flex-wrap: wrap;
    width: 104px;

    img {
      position: relative;
      z-index: 2;
      width: 56px;
      border-radius: 50%;
    }

    img + img {
      margin-left: -15px;
      z-index: 1;
    }
  }

  &__left-text {
    padding-left: 15px;
    width: calc(100% - 104px);

    strong {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $blue;
    }

    span {
      display: block;
      font-size: 16px;
      font-weight: 300;
      color: $teal;
    }
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;

    @include media-breakpoint-up(lg) {
      width: 33.33%;
    }
  }

  &__right {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 66.66%;
      text-align: right;
    }

    strong {
      font-size: 18px;
      font-weight: 600;
      color: $blue;
    }
  }

  .footer__nvm {
    span {
      height: 56px;
    }
  }
}
