// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

html,
body {
	background-color: $body-bg;
}

body {
	font-size: $font-size-sm;
	letter-spacing: -0.5px;
	@include media-breakpoint-up(lg) {
		font-size: $font-size-base;
	}

	&.is-open-gallery,
	&.is-open-search,
	&.is-open-menu {
		overflow: hidden;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;

	&--news {
		overflow: visible;
	}
}

h1,
.h1 {
	font-size: 2.5rem;
	line-height: 48px;
	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
		line-height: 72px;
	}
}

h2,
.h2 {
	font-size: 2rem;
	line-height: 40px;
	@include media-breakpoint-up(lg) {
		font-size: $h2-font-size;
		line-height: 56px;
	}
}

h3,
.h3 {
	font-size: 1.5rem;
	line-height: 32px;
	@include media-breakpoint-up(lg) {
		font-size: $h3-font-size;
		line-height: 48px;
	}
}

h4,
.h4 {
	font-size: 1.25rem;
	line-height: 24px;
	@include media-breakpoint-up(lg) {
		font-size: $h4-font-size;
		line-height: 40px;
	}
}

h5,
.h5 {
	font-size: 1.125rem;
	line-height: 24px;
	@include media-breakpoint-up(lg) {
		font-size: $h5-font-size;
		line-height: 32px;
	}
}

h6,
.h6 {
	font-size: 1.125rem;
	line-height: 24px;
	@include media-breakpoint-up(lg) {
		font-size: $h6-font-size;
		line-height: 28px;
	}
}

.page-widget .slick-track {
  margin: {
    right: 0;
    left: 0;
  }
}

.btn {
	padding: 0.836rem 1.25rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: -0.4px;
	text-align: center;
	@include media-breakpoint-up(lg) {
		padding: $input-btn-padding-y $input-btn-padding-x;
		font-size: $btn-font-size;
	}

	&.btn-secondary-alt {
		color: $white;
		background: $indigo;

		&:hover {
			color: $blue;
			background: $white;
		}
	}

	&.btn-light {
		color: $pink;
		border-color: $gray-300;
		background: $gray-300;

		&:hover {
			color: $white;
			border-color: $blue;
			background: $blue;
		}
	}

	&.btn-outline-secondary {
		border-color: $outline-border;
	}

	&.btn-link-secondary {
		color: $blue;
		padding: {
			right: 0;
			left: 0;
		}

		&:hover {
			color: $blue;
		}

		i {
			font-size: 14px;
			color: $blue;
		}
	}

  &.btn-link-secondary--alt {
    i {
      margin-left: 10px;
      color: $primary;
    }
  }

	&.btn-link-primary {
		color: $primary;
		padding: {
			right: 0;
			left: 0;
		}

		&:hover {
			color: $blue;
		}

		i {
			font-size: 14px;
		}
	}

	&.btn-link-white {
		color: $white;
		padding: {
			right: 0;
			left: 0;
		}

		i {
			font-size: 14px;
		}
	}

	&.btn-link-dark {
		color: $cyan;
		padding: {
			right: 0;
			left: 0;
		}

		&:hover {
			color: $blue;
		}

		i {
			margin-left: 10px;
			font-size: 14px;
		}
	}

	&.btn-dark {
		border-color: $cyan;
		background: $cyan;

		&:hover {
			border-color: darken($cyan, 5%);
			background: darken($cyan, 5%);
		}
	}

	&.btn-pink {
		color: $white;
		border-color: $pink;
		background: $pink;

		&:hover {
			border-color: darken($pink, 5%);
			background: darken($pink, 5%);
		}
	}

	&.btn-dark-alt {
		color: $white;
		border-color: $purple;
		background: $purple;

		&:hover {
			border-color: darken($purple, 5%);
			background: darken($purple, 5%);
		}
	}

	&.btn-circle {
		padding: 0;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		@include media-breakpoint-up(lg) {
			width: 3.5rem;
			height: 3.5rem;
		}

		&.btn-sm {
			width: 40px;
			height: 40px;
			@include media-breakpoint-up(lg) {
				width: 48px;
				height: 48px;
			}
		}

		&.btn-white {
			color: $blue;
			background: $white;
			box-shadow: 0 4px 4px rgba($black, 0.08);
		}

		&.btn-pink {
			color: $white;
			background: $pink;
		}

		&.btn-dark {
			background: $cyan;
		}
	}
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
	padding-right: 20px;
	padding-left: 20px;
	@include media-breakpoint-up(xxl) {
		padding-right: 40px;
		padding-left: 40px;
	}
}

.lead {
	font-size: 3rem;
	font-weight: $lead-font-weight;
	line-height: 56px;
	@include media-breakpoint-up(md) {
		font-size: $lead-font-size;
		line-height: 72px;
	}
}

.font-large {
	font-size: $font-size-base;
	@include media-breakpoint-up(md) {
		font-size: $font-size-lg;
	}
}

.font-medium {
	font-size: 0.875rem;
	font-weight: normal;
	@include media-breakpoint-up(md) {
		font-size: $font-size-sm;
	}
}

.font-small {
	font-size: 0.75rem;
	@include media-breakpoint-up(md) {
		font-size: 0.875rem;
	}
}

.font-xsmall {
	font-size: 0.625rem;
	@include media-breakpoint-up(md) {
		font-size: 0.75rem;
	}
}

select.form-control {
	padding-right: 45px;
	appearance: none;
	cursor: pointer;
	@include background-image(select-arrow, svg, right 20px center, no-repeat);
}


.form-check {
	padding-left: 0;
	position: relative;

	.form-check-input {
		opacity: 0;
    margin-left: 0;
		position: absolute;
		top: 4px;
		left: 6px;
    z-index: -1;
	}

	.form-check-label {
		padding-left: 35px;
		z-index: 2;
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 1.6;
		color: $blue;
		cursor: pointer;

		.contact-info & {
			color: $white;
		}

		&:after {
			position: absolute;
			top: 1px;
			left: 0;
			width: 24px;
			height: 24px;
			border: 1px solid rgba($blue, 0.25);
			border-radius: 4px;
			background-color: $white;
			transition: all 0.3s;
			content: '';
			@include background-image(check, svg, center center, no-repeat, 0 0);
		}
	}

	.form-check-input:checked + .form-check-label {
		&:after {
			background-color: $blue;
			background-size: 15px auto;
		}
	}
}

.form-group {
	position: relative;

	label {
		padding: 16px 20px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		font-size: 16px;
		letter-spacing: -0.4px;
		color: $pink;
		transition: 0.2s all;
		pointer-events: none;
		cursor: text;
	}

	.form-control {
		padding: 20px 20px 5px;
		position: relative;
		outline: 0;
	}

	.form-control:invalid {
		outline: 0;
	}

	.form-control:focus {
		z-index: 1;
		border-color: $teal;
	}

	&.is-active {
		.form-control {
			z-index: 1;
			border-color: $teal;
		}
	}

  .form-control:focus ~ label,
  .form-control ~ label.is-filled,
  .form-control:placeholder-shown ~ label,
  .form-control[type="date"] ~ label,
  select.form-control:valid ~ label,
  select.form-control#cta-subject ~ label,
  select.form-control.tiny-label ~ label
  {
		top: -10px;
		font-size: 12px;
	}
}

textarea.form-control {
	height: 128px;
}

.form-info {
	position: relative;
}

.form-error {
	margin-bottom: 1.5rem;
	margin-left: 10px;
	padding: 8px 20px;
	position: absolute;
	top: 8px;
	left: calc(100% + 20px);
	display: inline-flex;
	align-items: center;
	width: 100%;
	min-height: 40px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.2;
	letter-spacing: 0;
	color: $white;
	border-radius: 4px;
	background: $orange;

	&:after {
		margin-top: -8px;
		position: absolute;
		top: 50%;
		left: -8px;
		width: 0;
		height: 0;
		border-color: transparent $orange transparent transparent;
		border-style: solid;
		border-width: 8px 8px 8px 0;
		content: '';
	}
}

.popup-bg {
	margin: 0 auto;
	padding: 30px;
	position: relative;
	max-width: 350px;
	font-size: 14px;
	color: $blue;
	border-radius: 8px;
	background: $white;
  max-height: 90vh;
  overflow: auto;

	@include media-breakpoint-up(lg) {
		padding: 60px 80px;
		max-width: 552px;
		font-size: 16px;
		border-radius: 16px;
	}

	h4,
  .h4 {
		margin-bottom: 1.5rem;
	}

  .form-privacy {
    .form-check-input {
      margin-left: 0;
    }

    div {
      padding-left: 1.25rem;
    }
  }
}

button.mfp-close {
	top: 15px !important;
	right: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	font-size: 0;
	font-weight: 400;
	color: transparent;

	&:after {
		font-family: $font-family-icon;
		font-size: 14px;
		color: $blue;
		content: '\e90e';
	}
}

.mfp-bg.mfp-popup,
.mfp-wrap.mfp-popup {
  z-index: 99999;
}

.fancybox__container {
  z-index: 999999 !important;
}

[disabled],
.disabled {
  opacity: .4;
  pointer-events: none;
}

.disabled.clickable {
  pointer-events: all;
  opacity: 1;
}

section {
  p {
    b,
    strong {
      font-weight: 700;
    }
  }
}