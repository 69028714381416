.account-notification {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__search {
    margin-bottom: 15px;
    padding: 30px 20px;
    position: relative;
    border-radius: 16px;
    background: #fff3cd;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 125px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 40px 60px;
    }
  }

  &__search-text {
    width: 100%;
    text-align: center;
  }
}

.account-popup {
  width: 100%;
  overflow: hidden;

  .close-logout {
    padding: 1rem 1.5rem;
    line-height: 1.375;
    font-size: 1rem;
    height: auto;
  }
}

.card-broker {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 30px;
  }

  &--top {
    border-radius: 8px 8px 0 0;
  }

  &--bottom {
    border-radius: 0 0 8px 8px;
  }

  &__title {
    margin: -20px -20px 25px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 25px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 20px;
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 30px;
      padding: 0 30px 20px;
      width: calc(100% + 60px);
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
  }

  &__block-text {
    padding-left: 15px;
    width: calc(100% - 80px);

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 300;
      color: $pink;

      i {
        margin-right: 10px;
      }
    }
  }

  &__info {
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        span,
        a {
          padding-left: 35px;
          position: relative;
          display: inline-block;
          align-items: flex-start;
          font-size: $font-size-sm;
          color: $blue;

          small {
            display: block;
            font-size: 14px;
            color: $teal;
          }

          &:hover {
            text-decoration: none;
            color: $pink;
          }

          i {
            position: absolute;
            top: 3px;
            left: 0;
            width: 30px;
            color: $cyan;

            &.icon-mail {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &__list {
    ul {
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 3px;
        position: relative;
        width: 100%;
        font-size: 16px;
        color: $blue;
        border-right: 1px solid $gray-500;
        padding: {
          top: 4px;
          bottom: 4px;
          left: 25px;
        }

        &:last-child {
          margin: 0;
          border-right: 0;
        }

        &:before {
          position: absolute;
          top: 7px;
          left: 1px;
          font-family: $font-family-icon;
          font-size: 10px;
          color: $cyan;
          content: '\e909';
        }
      }
    }
  }

  &__logo {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        margin: 10px 5px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba($black, 0.04);

        img {
          padding: 5px 10px;
          width: auto;
          height: 70px;
        }
      }
    }
  }

  &--logo {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    h6 {
      margin-bottom: 0;
      font-size: 16px;
    }

    ul {
      margin-bottom: 0;
    }
  }
}

.card-house {
  position: relative;
  display: block;
  width: 100%;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &__content {
    padding-top: 15px;
    width: 100%;
    font-size: $font-size-sm;
    font-weight: 300;
    color: $teal;

    h6 {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      padding-bottom: 12px;
      font-size: $font-size-sm;
    }
  }

  &__list-item {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    &:after {
      margin: 0 10px;
      display: inline-block;
      width: 4px;
      height: 4px;
      background: $teal;
      transform: rotate(45deg);
      content: '';
      @include media-breakpoint-up(xl) {
        margin: 0 15px;
      }
    }

    &.city {
      font-size: 20px;
      font-weight: 500;
    }

    a {
      text-decoration: none;
      color: $cyan;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: rgba(242, 232, 225);
  }

  &__bar-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    //width: attr(data-percentage %); // not supported yet


    height: 4px;
    background: $cyan;

    span {
      padding: 0 10px;
      position: absolute;
      bottom: calc(100% + 15px);
      left: calc(100% - 50px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 26px;
      font-size: 12px;
      text-align: center;
      color: $blue;
      border-radius: 4px 4px 0 0;
      background: $white;

      &:after {
        margin-left: -4px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 0;
        height: 0;
        border-color: $white transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0;
        content: '';
      }
    }
  }

  &__heart {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 10px;
    color: $red;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      top: 26px;
      right: 26px;
    }

    i.icon-heart-alt {
      color: $blue;
    }

    &.is-active,
    &:hover {
      text-decoration: none;

      i {
        &:before {
          content: '\e919';
          color: $blue;

        }

        &.icon-heart-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      text-decoration: none;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      border-radius: 8px;
      aspect-ratio: 3/2;
      object-fit: cover;
    }
  }

  &__label {
    position: absolute;
    top: 22px;
    left: 0;
    z-index: 1;

    /* for every &__label, add 35px to the top, with -13px being start position */
    @for $i from 2 through 10 {
      &:nth-of-type(#{$i}) {
        top: calc(-13px + (#{$i} * 35px))
      }
    }

    span {
      padding: 0 20px 0 12px;
      display: inline-flex;
      align-items: center;
      height: 24px;
      font-size: 12px;
      font-weight: 600;
      color: $white;
      background: $cyan;
      clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
    }
  }

  &__price {
    color: $cyan;
  }
}

.card-house-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  &__content {
    width: 100%;
    font-size: $font-size-sm;
    font-weight: 300;
    color: $teal;
    @include media-breakpoint-up(md) {
      padding-left: 20px;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 57%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 59.5%;
    }

    p {
      margin-bottom: 5px;
      color: $pink;
    }
  }

  &__list {
    margin: 0;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 300;
    color: $pink;
    @include media-breakpoint-up(xl) {
      padding-bottom: 20px;
      font-size: $font-size-sm;
    }
  }

  &__list-item {
    margin-right: 20px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;

    i {
      margin-right: 10px;
      font-size: 1rem;
      color: $pink;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__number {
    display: inline-flex;
    align-items: center;
    color: $cyan;

    i {
      margin-right: 10px;
      font-size: 12px;
    }
  }

  &__price {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: $blue;
    @include media-breakpoint-up(xl) {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__image {
    margin-bottom: 25px;
    position: relative;
    display: block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 43%;
      border-radius: 4px;
    }
    @include media-breakpoint-up(xxl) {
      width: 40.5%;
    }

    &:hover {
      text-decoration: none;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      border-radius: 8px;
      aspect-ratio: 3/2;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 4px;
      }
    }
  }

  &__tag {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;

    span {
      padding: 3px 8px;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 2px;
      background: $green;
    }
  }
}

.card-link {
  margin-bottom: 18px;
  padding: 20px 80px 20px 25px;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  border-radius: 8px;
  background: $gray-300;

  &:after {
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-family: $font-family-icon;
    font-size: 15px;
    font-weight: 400;
    color: $white;
    border-radius: 50%;
    background: $cyan;
    transform: translateY(-50%);
    content: '\e904';
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 300;
    color: $pink;
  }

  strong {
    margin-bottom: 5px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
  }
}

.card-search {
  padding-left: 80px;
  position: relative;
  width: 100%;
  border-radius: 10px 0 0 10px;
  background: $white;
  box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

  label {
    padding-left: 18px;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    height: 60px;
    font-size: $font-size-sm;
    font-weight: 600;
    color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-color: transparent transparent transparent $white;
    border-style: solid;
    border-width: 30px 0 30px 24px;
    content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
  }

  &__search-input {
    padding-right: 70px;
    width: 100%;
    height: 60px;
    font-size: $font-size-sm;
    font-weight: 300;
    color: $teal;
    border: 0;
    background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

    &::placeholder {
      color: $teal;
    }
  }

  &__search-btn {
    padding-top: 4px;
    position: absolute;
    top: 6px;
    right: 5px;
    width: 48px;
    height: 48px;
    font-size: $font-size-sm;
    color: $white;
    border: 0;
    border-radius: 50%;
    background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
  }

  &--select {
    padding-right: 245px;
  }

  &__select {
    padding-right: 20px;
    position: absolute;
    top: 15px;
    right: 40px;
    width: 210px;
    border-left: 1px solid $light-border;

    select {
      padding: 0 40px 0 15px;
      height: 48px;
      font-size: 16px;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

.card-social {
  margin-bottom: 18px;
  padding: 20px 30px 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 8px;
  background: $gray-300;

  &__title {
    margin: -20px -30px 25px;
    padding: 20px 30px;
    width: calc(100% + 60px);
    border-radius: 8px 8px 0 0;
    background: $blue;

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;
        margin-bottom: 8px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $white;
          transition: all 0.3s;
          box-shadow: 0 8px 8px rgba($black, 0.08);

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

.dashboard-header {
  margin-bottom: 30px;
  position: relative;
  z-index: 99;
  width: 100%;
  transition: all 0.3s;
  @include media-breakpoint-up(xl) {
    margin-bottom: 85px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 0;
    height: 0;
    border-color: transparent transparent $cyan transparent;
    border-style: solid;
    border-width: 0 9px 16px;
    content: '';
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    display: block;
    width: 140px;
    @include media-breakpoint-up(xl) {
      width: 180px;
    }
    @include media-breakpoint-up(xxl) {
      width: 210px;
    }

    img {
      display: block;
      width: 100%;
      transition: all 0.3s;
    }
  }

  &__right {
    position: relative;
    z-index: 2;
  }

  &__top {
    position: relative;
    width: 100%;
    border-top: 16px solid $indigo;
    background-color: $blue;
    @include media-breakpoint-up(xl) {
      border-top: 0;
      border-radius: 0 0 16px 16px;
      @include background-image(dashboard-bg, svg, center center, no-repeat, cover);
    }
  }

  &__top-inner {
    padding: 18px 0;
    height: 88px;
    @include media-breakpoint-up(xl) {
      padding: 0;
      height: 136px;
    }
  }

  &__menu {
    width: 100%;
    overflow: auto;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: visible;
    }
  }

  &__menu-list {
    margin: 0;
    padding: 15px 20px 20px;
    display: flex;
    white-space: nowrap;
    @include media-breakpoint-up(xl) {
      padding: 0;
      flex-wrap: wrap;
      justify-content: center;
      width: auto;
      white-space: normal;
    }
  }

  &__menu-item {
    margin-right: 35px;
    @include media-breakpoint-up(xl) {
      margin-right: 0;
      margin-left: 5px;
    }
    @include media-breakpoint-up(xxl) {
      margin-left: 20px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-left: 40px;
    }

    &.is-active {
      .dashboard-header__menu-icon {
        color: $cyan;
      }

      .dashboard-header__menu-link {
        color: $blue;
        @include media-breakpoint-up(xl) {
          color: $white;
        }

        &:after {
          width: 36px;
          @include media-breakpoint-up(xl) {
            width: 100%;
          }
        }
      }
    }

    &:last-child {
      padding-right: 40px;
      @include media-breakpoint-up(xl) {
        padding-right: 0;
      }
    }
  }

  &__menu-icon {
    position: absolute;
    top: 25px;
    left: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
    color: $pink;
    transform: translateX(-50%);
  }

  &__menu-link {
    padding: 10px 0 5px;
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: $light-gray;
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      padding: 50px 13px 0;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      height: 136px;
      color: $pink;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 16px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: $blue;
      transition: all 0.3s;
      content: '';
      @include media-breakpoint-up(xl) {
        height: 4px;
        background: $cyan;
      }
    }

    &:hover {
      text-decoration: none;
      color: $blue;
      @include media-breakpoint-up(xl) {
        color: $white;
      }

      .dashboard-header__menu-icon {
        color: $cyan;
      }

      &:after {
        width: 100%;
      }
    }
  }
}

.dashboard-heading {
  margin-bottom: 35px;
  position: relative;
  z-index: 99;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
  }

  &__text {
    color: $blue;
  }

  h2 {
    margin-bottom: 18px;
    font-weight: 400;
    color: $blue;

    strong {
      font-weight: 700;
    }
  }
}

.faq-section {
  --size-link-asset: 32px;
  margin-bottom: 60px;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
    margin-bottom: 120px;
  }

  h2 {
    margin-bottom: 20px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }

    strong {
      color: $pink;
    }
  }

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

  &__top {
    color: $blue;
    @include media-breakpoint-up(lg) {
      color: $pink;
    }
  }

  &__holder {
    margin-bottom: 20px;
    padding: 25px;
    width: 100%;
    border-radius: 8px;
    background-color: $gray-300;
    @include media-breakpoint-up(md) {
      padding: 45px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
      padding: 85px;
      border-radius: 16px;
    }

    h3 {
      margin-bottom: 25px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 50px;
      }
    }
  }

  &__item {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid $light;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &.is-active {
      .faq-section__link {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__link {
    padding: 10px 35px 10px 0;
    position: relative;
    display: block;
    font-weight: 600;
    color: $blue;

    @include media-breakpoint-up(lg) {
      padding: 0px 30px 0px 0;
    }

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

    i {
      position: absolute;
      top: 13px;
      right: 0;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 8px;
      transition: all 0.3s;
      @include media-breakpoint-up(lg) {
        margin-top: -15px;
        top: 50%;
        width: 30px;
        height: 30px;
        font-size: 10px;
      }
    }

    &.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
  }

  &__panel {
    display: none;
    @include media-breakpoint-up(xl) {
      padding-right: 100px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  &--inner {
    padding-top: 20px;
    @include media-breakpoint-up(xl) {
      padding-top: 80px;
    }

    .faq-section__holder {
      @include media-breakpoint-up(xl) {
        padding: 30px 60px;
      }
    }

    &:after {
      display: none;
    }
  }
}

.filter-popup {
  width: 100%;

  .filter-popup__wrapper {
    position: relative;

    .filter-popup__wrapper__inner {
      max-height: 50vh;
      overflow: scroll;
      padding-bottom: 50px;

      .select2 {
        .select2-selection {
          height: 56px;
          .select2-selection__rendered {
            line-height: 56px;
          }

          .select2-selection__clear {
            height: 100%;
            margin-right: 32px;
            padding-right: 0;
            font-size: 20px;
          }

          .select2-selection__arrow {
            height: 56px;
            width: 32px;
          }
        }
      }
    }

    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      right: 0;
      z-index: 1;
    }
  }
}

// begin footer
.footer {
  position: relative;
  width: 100%;

  &.extra-margin {
    margin-bottom: 96px;
  }

  &__top {
    width: 100%;
    @include media-breakpoint-up(md) {
      padding: 45px 0;
      border-bottom: 1px solid $light-border;
    }
    @include media-breakpoint-up(xl) {
      padding: 65px 0 60px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-panel {
    display: none;
    @include media-breakpoint-up(md) {
      margin: 0 !important;
      padding: 0 !important;
      display: block !important;
      height: auto !important;
    }
  }

  &__list-item {
    width: 100%;
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(md) {
      padding: 0 10px 0 0;
      width: 20%;
      border-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      width: 16.666%;
    }

    &.is-active {
      .footer__list-link {
        i {
          transform: rotate(-180deg);
        }
      }
    }
    .footer__nvm {
      padding: 30px 0;
    }
  }

  &__list-link {
    margin-bottom: 0;
    padding: 20px 0;
    position: relative;
    font-size: 1.12rem;
    font-weight: 700;
    color: $blue;
    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      padding: 0;
    }

    i {
      position: absolute;
      top: 30px;
      right: 0;
      font-size: 7px;
      transition: all 0.3s;
    }
  }

  &__logo {
    display: block;
    max-width: 240px;

    img {
      width: 100%;
    }
  }

  &__nvm {
    padding: 10px 0;
    width: 100%;
    text-align: start;
    @include media-breakpoint-up(md) {
      text-align: center;
    }

    img {
      margin-right: 10px;
      max-height: 28px;
    }

    span {
      padding: 0 18px;
      display: inline-flex;
      align-content: center;
      align-items: center;
      height: 40px;
      font-size: 14px;
      font-weight: 300;
      color: $blue;
      border-radius: 4px;
      background: $light-border;

      strong {
        margin-left: 5px;
        font-weight: 600;
      }
    }
  }

  &__menu {
    margin: 0;
    padding: 0 0 20px;
    width: 100%;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    li {
      margin-bottom: 6px;
      width: 100%;

      a {
        display: inline-block;
        font-size: $font-size-sm;
        color: rgba($pink, 0.7);

        &:hover {
          text-decoration: none;
          color: $pink;
        }
      }
    }
  }

  &__social {
    margin: 0;
    padding: 0 0 20px;
    width: 100%;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    li {
      margin-bottom: 6px;
      width: 100%;

      a {
        display: inline-flex;
        align-items: center;
        font-size: $font-size-sm;
        color: rgb($pink, 0.7);

        &:hover {
          text-decoration: none;
          color: $pink;
        }

        i {
          width: 30px;
          color: $pink;

          &.icon-twitter {
            font-size: 13px;
          }

          &.icon-youtube {
            font-size: 11px;
          }
        }
      }
    }
  }

  &__center {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(lg) {
      border-bottom: 0;
    }

    &:after {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $light-border;
      content: '';
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__copyright {
    ul {
      margin: 0;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 8px 0;
        display: inline-flex;
        align-items: center;

        a {
          font-size: 12px;
          color: $teal;
        }

        &:before {
          margin: 0 20px;
          width: 4px;
          height: 4px;
          background: $teal;
          transform: rotate(45deg);
          content: '';
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &__center-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: $blue;
    @include media-breakpoint-up(lg) {
      padding: 25px 0;
    }

    strong {
      font-weight: 700;
    }
  }

  &__center-left {
    padding: 20px 0;
    display: inline-flex;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    span {
      padding: 7px 0 0 10px;
      display: inline-block;
    }
  }

  &__center-right {
    padding: 30px 0 20px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    p {
      margin: 0 0 0 10px;
    }
  }

  &__bottom {
    position: relative;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    border-bottom: solid 3px $light-alt;
    background: $blue;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 100%;
      background: $white;
      content: '';
      @include media-breakpoint-up(xl) {
        width: 65px;
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 0;
      height: 0;
      border-color: transparent transparent $cyan transparent;
      border-style: solid;
      border-width: 0 24.5px 40px;
      content: '';
      @include media-breakpoint-up(xl) {
        left: 40px;
      }
    }

    p {
      margin: 0;
      display: block !important;
      padding-left: 30px;

      @include media-breakpoint-up(lg) {
        padding-left: 45px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;

      li {
        display: inline-flex;
        align-items: center;

        a {
          color: $white;
        }

        &:before {
          margin: 0 11px;
          width: 4px;
          height: 4px;
          background: $teal;
          transform: rotate(45deg);
          content: '';
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &__bottom-inner {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    color: $white;
  }
}

.cookie-consent {
  position: fixed;
  bottom: 8px;
  right: 8px;
  padding: 32px;
  width: fit-content;
  max-width: calc(100% - 16px);
  z-index: 99999;
  border-radius: 16px;
  background-color: $blue;
  border: 1px solid $purple;

  @include media-breakpoint-up(sm) {
    max-width: 400px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 500px;
  }

  h4,
  .cookie-consent__text__inner *,
  label {
    color: $white !important;
  }

  .cookie-consent__text__inner * {
    font-size: 14px;

    @include media-breakpoint-up(xl) {
      font-size: 16px;
    }
  }

  .cookie-consent__form--list {
    margin: 24px 0;

    .form-check {
      margin-bottom: 8px;

      &.disabled {
        opacity: .6;
      }

      .form-check-input:checked + .form-check-label:after {
        background-color: $purple;
      }
    }
  }

  &:not(.cookie-consent--unaccepted) {
    display: none;
  }
}
// end footer

.form-block {
  padding: 30px;
  position: relative;
  width: 100%;
  font-size: 14px;
  border-radius: 16px;
  background: $gray-300;
  @include media-breakpoint-up(xl) {
    padding: 60px 80px;
    font-size: 16px;
    border-radius: 12px;
    margin: {
      bottom: 30px;
    };
  }
  margin: {
    bottom: 20px;
  }

  label {
    margin-bottom: 10px;
    font-weight: 600;
    color: $blue;
  }

  h4 {
    margin-bottom: 1.5rem;
  }

  h6 {
    margin-bottom: 1.5rem;
    font-size: 18px;
    font-weight: 600;
    color: $blue;
  }

  &__tabs {
    margin-bottom: 25px;

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      border-radius: 5px;
      @include media-breakpoint-up(lg) {
        padding-top: 5px;
      }

      li {
        &:first-child {
          a {
            border-left: 1px solid rgba($pink, 0.25);
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          a {
            border-radius: 0 5px 5px 0;
          }
        }

        a {
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 32px;
          font-size: 14px;
          font-weight: 600;
          color: $blue;
          border-top: 1px solid rgba($pink, 0.25);
          border-right: 1px solid rgba($pink, 0.25);
          border-bottom: 1px solid rgba($pink, 0.25);
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            border-color: $blue;
            background: $blue;
          }
        }

        &.is-active {
          a {
            text-decoration: none;
            color: $white;
            border-color: $blue;
            background: $blue;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__list-item {
    margin-bottom: 30px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: calc(50% - 10px);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
      width: calc(50% - 40px);
    }
  }

  &__list-full {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: calc(100% - 10px);
    }
    @include media-breakpoint-up(xl) {
      width: calc(100% - 40px);
    }
  }

  &__title {
    padding-right: 130px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-up(xxl) {
      padding-right: 0;
    }
  }

  &__title-label {
    margin-bottom: 1.5rem;
    margin-left: 10px;
    padding: 0 10px;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    color: $white;
    border-radius: 4px;
    background: $orange;
    @include media-breakpoint-up(xxl) {
      position: static;
    }

    &.active {
      background: $green;
    }
  }

  .form-block {
    padding: 0 0 0 30px;
  }

  .subscription {
    padding: 20px 24px;
    background: $white;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    @include media-breakpoint-up(lg) {
      padding: 24px 32px;
    }

    h6 {
      margin-bottom: 8px;

      &.title-muted {
        color: rgba(0, 0, 0, 0.25)
      }
    }

    input.input-toggle {
      display: none;

      + .toggle {
        cursor: pointer;
        height: 24px;
        width: 56px;
        background-color: $cyan;
        border-radius: 13px;
        position: relative;
        margin: 0 8px;
        display: inline-block;

        &::before {
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $white;
          position: absolute;
          top: 2px;
          right: 2px;
        }
      }

      &:not(:checked) {
        + .toggle {
          background-color: $gray-500;

          &::before {
            left: 2px;
            right: auto;
          }
        }
      }
    }

    .toggle-btn {
      height: 24px;
      width: 56px;
      background-color: $cyan;
      border-radius: 13px;
      position: relative;
      margin: 0 8px;
      display: inline-block;

      &.disabled {
        background-color: $gray-500;

        &::before {
          left: 2px;
          right: auto;
        }
      }

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $white;
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  transition: all 0.3s;

  .is-open-menu & {
    background: $blue;
    @include media-breakpoint-up(xl) {
      background: transparent;
    }
  }

  &__top {
    position: relative;
    width: 100%;
    height: 16px;
    background: $indigo;
    @include media-breakpoint-up(lg) {
      height: 40px;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 9px;
      height: 100%;
      background: $blue;
      transition: all 0.3s;
      content: '';
      @include media-breakpoint-up(lg) {
        width: 65px;
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 0;
      height: 0;
      border-color: transparent transparent $cyan transparent;
      border-style: solid;
      border-width: 0 9px 16px;
      content: '';
      @include media-breakpoint-up(lg) {
        left: 41px;
        border-width: 0 24.5px 40px;
      }
    }
  }

  &__top-nav {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
      height: 40px;
    }

    li {
      display: inline-flex;
      align-items: center;

      a {
        display: inline-block;
        color: $white;
      }

      &:after {
        margin: 0 5px;
        content: '|';
        @include media-breakpoint-up(lg) {
          margin: 0 15px;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  &__logo {
    position: relative;
    display: block;
    width: 140px;
    @include media-breakpoint-up(xl) {
      width: 188px;
    }

    img {
      display: block;
      width: 100%;
      transition: all 0.3s;
    }

    .is-dark {
      opacity: 0;
    }

    .is-light {
      position: absolute;
      top: 0;
      left: 0;
    }

    .is-open-menu & {
      .is-dark {
        opacity: 0;
      }

      .is-light {
        opacity: 1;
      }
    }
  }

  &__bottom {
    padding: 16px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  &--step {
    .header__bottom {
      padding: 15px 0;
      @include media-breakpoint-up(xl) {
        padding: 47px 0;
      }
    }
  }

  &__info-btn {
    margin-right: 12px;
  }

  &__bottom-left {
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  &__bottom-right {
    display: inline-flex;
    align-items: center;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    border: 1px solid rgba($indigo, 0.9);
    border-radius: 50px;
    background: rgba($indigo, 0.9);
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      width: 48px;
      height: 48px;
      font-size: $font-size-sm;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0 20px;
      width: auto;
      border-radius: 5px;
    }

    .is-open-search & {
      opacity: 0;
      visibility: hidden;
    }

    .is-open-menu & {
      color: $white;
      border-color: rgba($indigo, 0.9);
      background: rgba($indigo, 0.9);
    }

    &:hover {
      text-decoration: none;
      color: $blue;
      border-color: $light;
      background: $light;
    }

    i {
      @include media-breakpoint-up(xxl) {
        margin-right: 10px;
      }
    }
  }

  &__search {
    margin-top: 15px;
    position: relative;
    width: 100%;
    transition: all 0.3s;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-right: 120px;
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      visibility: hidden;
      width: 500px;
      transform: translate(-50%, -50%);
    }
    @include media-breakpoint-up(xl) {
      width: 790px;
    }

    .is-open-search & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__search-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__search-input {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: $blue;
      @include media-breakpoint-up(lg) {
        top: 15px;
        right: 15px;
      }
    }

    input {
      padding: 0 60px 0 15px;
      width: 100%;
      height: 48px;
      font-size: 15px;
      font-weight: 300;
      color: $pink;
      border: 0;
      border-radius: 4px;
      background: $gray-300;
      @include media-breakpoint-up(lg) {
        padding: 0 60px 0 25px;
        height: 56px;
        font-size: $font-size-sm;
        color: $teal;
        border: 1px solid $light;
        background: $light-alt;
      }

      &::placeholder {
        color: $pink;
        @include media-breakpoint-up(lg) {
          color: $teal;
        }
      }
    }
  }

  &__search-btn {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 12px;
    color: $white;
    border: 1px solid rgba($indigo, 0.9);
    border-radius: 50%;
    background: rgba($indigo, 0.9);
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      width: 48px;
      height: 48px;
      font-size: $font-size-sm;
    }
    @include media-breakpoint-up(xxl) {
      margin-left: 15px;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
      border-color: $light;
      background: $light;
    }

    .is-open-menu & {
      color: $white;
      border-color: rgba($indigo, 0.9);
      background: rgba($indigo, 0.9);
    }

    .is-open-search & {
      i {
        font-size: 10px;
        @include media-breakpoint-up(xl) {
          font-size: 14px;
        }

        &:before {
          content: '\e90e';
        }
      }
    }
  }

  &__toggle {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid rgba($indigo, 0.9);
    border-radius: 50%;
    background: rgba($indigo, 0.9);
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      width: 48px;
      height: 48px;
    }

    span {
      position: relative;
      display: block;
      width: 14px;
      height: 2px;
      background: $white;
      transition: all 0.3s;

      &:before,
      &:after {
        position: absolute;
        top: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $white;
        transition: all 0.3s;
        content: '';
      }

      &:after {
        top: 4px;
      }
    }

    .is-open-menu & {
      border-color: rgba($indigo, 0.9);
      background: rgba($indigo, 0.9);

      span {
        background: none;

        &:before {
          top: 0;
          background: $white;
          transform: rotate(45deg);
        }

        &:after {
          top: 0;
          background: $white;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__menu {
    position: fixed;
    top: 84px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: calc(100vh - 84px);
    background: $blue;
    transition: all 0.3s;
    overflow: auto;
    @include media-breakpoint-up(lg) {
      top: 119px;
      height: calc(100vh - 119px);
    }
    @include media-breakpoint-up(xl) {
      padding: 0 0 0 60px;
      position: static;
      opacity: 1;
      visibility: visible;
      width: auto;
      height: auto;
      background: transparent;
      overflow: visible;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 80px;
    }
    @include media-breakpoint-up(xxxl) {
      padding-left: 143px;
    }

    .is-open-search & {
      @include media-breakpoint-up(xl) {
        opacity: 0;
        visibility: hidden;
      }
    }

    .is-open-menu & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__menu-inner {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: calc(100vh - 163px);
    @include media-breakpoint-up(lg) {
      height: calc(100vh - 123px);
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
      display: block;
      max-width: inherit;
      height: auto;
    }
  }

  &__menu-list {
    margin: 0;
    padding: 0;
    width: 100%;
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
      width: auto;
    }
  }

  &__menu-item {
    @include media-breakpoint-up(xl) {
      margin-right: 40px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.has-child {
      position: relative;
      @include media-breakpoint-up(xl) {
        position: static;
      }

      .header__menu-link {
        padding-right: 25px;
        position: relative;

        i {
          @include media-breakpoint-up(xl) {
            margin-top: -2px;
            position: absolute;
            top: 50%;
            right: 5px;
            z-index: 3;
            display: inline-block;
            align-items: center;
            justify-content: center;
            width: auto;
            height: auto;
            font-size: 7px;
          }
        }
      }
    }

    &.is-active {
      .header__menu-link::after {
        content: "";
        background-color: $cyan;
        position: absolute;
        width: 40%;
        height: 2px;
        bottom: 2px;
        left: 0;

        @include media-breakpoint-up(xl) {
          bottom: 42px;
          width: 100%;
        }
      }
    }

    &.submenu-visible {
      .header__submenu {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      .header__menu-link {
        color: $white;
        @include media-breakpoint-up(xl) {
          color: $teal;
        }
      }
    }
  }

  &__menu-link {
    padding: 10px 0;
    position: relative;
    display: block;
    font-weight: 300;
    color: $white;
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      padding: 0;
      display: inline-flex;
      align-items: center;
      height: 120px;
      font-weight: 600;
      color: $white;
    }

    &:hover {
      text-decoration: none;
      color: $white;
      @include media-breakpoint-up(xl) {
        color: $pink;
      }
    }
  }

  &__arrow {
    position: absolute;
    width: 100%;
    text-align: right;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    padding-left: 14px;
    top: 10px;
    right: 5px;
    z-index: 3;
    align-items: center;
    font-size: 7px;
    color: $white;
    transition: all 0.3s;

    i::before {
      transtion: .3s ease;
      display: inline-block;
    }

    &.is-active {
      transform: none !important;
      i::before {
        transform: rotate(-180deg);
      }
    }
  }

  &__submenu {
    padding: 10px 17px 10px 25px;
    z-index: 99;
    display: none;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding: 65px 0 0;
      position: absolute;
      top: 100%;
      left: 0;
      display: block !important;
      opacity: 0;
      visibility: hidden;
      border-radius: 0 0 15px 15px;
      background: $white;
      transition: all 0.3s;
    }

    .container {
      @include media-breakpoint-down(lg) {
        padding: 0;
        max-width: 100%;
      }
    }
  }

  &__submenu-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__submenu-left {
    display: inline-flex;
    align-items: center;
    font-size: 14px;

    span {
      padding: 8px 5px 0;
      display: block;
      font-weight: 300;
      color: $teal;
    }

    strong {
      padding-top: 8px;
      display: block;
      font-weight: 600;
      color: $blue;
    }
  }

  &__submenu-right {
    padding-right: 50px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    color: $teal;

    img {
      margin-right: 10px;
    }
  }

  &__submenu-list {
    padding: 0;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding: 0 0 65px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__submenu-item {
    width: 100%;
    counter-increment: step-counter;

    @include media-breakpoint-up(xl) {
      margin-bottom: 20px;
      width: 25%;
    }
    .subtitle span {
      display: none;
    }
  }

  &__submenu-link {
    padding: 10px 0;
    position: relative;
    display: block;
    font-weight: 300;
    color: $white;
    @include media-breakpoint-up(xl) {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: 600;
      color: $pink;
      min-height: 92px;
    }

    i {
      @include media-breakpoint-down(lg) {
        position: absolute;
        top: 10px;
        right: 0;
        font-size: 10px;
      }
    }

    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }

  &__submenu-img {
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 80px;
    @include background-image(menu-bg, svg, center center, no-repeat);

    .menu-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px;
      position: absolute;
      width: 80px;
      height: 100%;
    }

    img {
      max-height: 50px;
    }
  }

  &__submenu-text {
    padding-right: 10px;
    padding-left: 10px;
    width: calc(100% - 104px);

    &::before {
      display: block;
      content: 'Stap ' counter(step-counter);
      font: {
        weight: 300;
        size: 14px;
      }
      line-height: 1.5;
      letter-spacing: -.3px;
      color: $pink;

      @include media-breakpoint-between(md, xxl) {
        font-size: 12px;
      }
    }

    @include media-breakpoint-up(xxl) {
      padding-left: 25px;
    }

    strong {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: 600;
      color: $blue;

      i {
        margin-left: 8px;
        font-size: 12px;
      }

      @include media-breakpoint-between(md, xxl) {
        font-size: 14px;
      }
    }
  }

  &__submenu-bottom {
    padding: 18px 0;
    width: 100%;
    border-top: 1px solid $gray-500;
  }

  &__overlay {
    @include media-breakpoint-up(lg) {
      position: fixed;
      top: 124px;
      left: 0;
      z-index: 98;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      min-height: calc(100vh - 124px);
      background: $blue;
      transition: all 0.3s;
    }
    @include media-breakpoint-up(xl) {
      top: 160px;
      min-height: calc(100vh - 160px);
    }

    .is-menu-open &,
    .is-open-menu & {
      @include media-breakpoint-up(xl) {
        opacity: 0.5;
        visibility: visible;
      }
    }

    .is-open-search & {
      @include media-breakpoint-up(lg) {
        opacity: 0.5;
        visibility: visible;
      }
    }
  }

  &--light {
    background: transparent;

    .header__logo {
      .is-dark {
        opacity: 1;
      }

      .is-light {
        opacity: 0;
      }

      .is-open-menu & {
        .is-dark {
          opacity: 0;
        }

        .is-light {
          opacity: 1;
        }
      }
    }

    .header__btn {
      color: $blue;
      border: 1px solid $light;
      background: transparent;

      .is-open-menu & {
        color: $white;
        border-color: rgba($indigo, 0.9);
        background: rgba($indigo, 0.9);
      }

      &:hover {
        text-decoration: none;
        color: $blue;
        border-color: $light;
        background: $light;
      }
    }

    .header__search-btn {
      color: $blue;
      border: 1px solid $light;
      background: transparent;

      &:hover {
        text-decoration: none;
        color: $blue;
        border-color: $light;
        background: $light;
      }

      .is-open-menu & {
        color: $white;
        border-color: rgba($indigo, 0.9);
        background: rgba($indigo, 0.9);
      }
    }

    .header__toggle {
      border: 1px solid $light;
      background: rgba($indigo, 0);

      span {
        background: $blue;

        &:before,
        &:after {
          background: $blue;
        }
      }

      .is-open-menu & {
        border-color: rgba($indigo, 0.9);
        background: rgba($indigo, 0.9);

        span {
          background: none;

          &:before {
            background: $white;
          }

          &:after {
            background: $white;
          }
        }
      }
    }

    .header__top {
      &:after {
        background: $white;

        .is-open-menu & {
          background: $blue;
        }
      }
    }

    .header__menu-link {
      @include media-breakpoint-up(xl) {
        color: $blue;
      }
    }
  }

  .contact &,
  &--search {
    @include media-breakpoint-down(md) {
      .header__search {
        display: none;
      }
    }
  }

  &--light-mobile {
    @include media-breakpoint-down(md) {
      background: transparent;

      .header__logo {
        .is-dark {
          opacity: 1;
        }

        .is-light {
          opacity: 0;
        }

        .is-open-menu & {
          .is-dark {
            opacity: 0;
          }

          .is-light {
            opacity: 1;
          }
        }
      }

      .header__btn {
        color: $blue;
        border: 1px solid $light;
        background: transparent;

        .is-open-menu & {
          color: $white;
          border-color: rgba($indigo, 0.9);
          background: rgba($indigo, 0.9);
        }

        &:hover {
          text-decoration: none;
          color: $blue;
          border-color: $light;
          background: $light;
        }
      }

      .header__search-btn {
        color: $blue;
        border: 1px solid $light;
        background: transparent;

        &:hover {
          text-decoration: none;
          color: $blue;
          border-color: $light;
          background: $light;
        }

        .is-open-menu & {
          color: $white;
          border-color: rgba($indigo, 0.9);
          background: rgba($indigo, 0.9);
        }
      }

      .header__toggle {
        border: 1px solid $light;
        background: rgba($indigo, 0);

        span {
          background: $blue;

          &:before,
          &:after {
            background: $blue;
          }
        }

        .is-open-menu & {
          border-color: rgba($indigo, 0.9);
          background: rgba($indigo, 0.9);

          span {
            background: none;

            &:before {
              background: $white;
            }

            &:after {
              background: $white;
            }
          }
        }
      }

      .header__top {
        &:after {
          background: $white;

          .is-open-menu & {
            background: $blue;
          }
        }
      }
    }
  }
}

.heading {
  margin-bottom: 60px;
  padding-top: 130px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
  z-index: 3;
  background-color: $blue;
  @include background-image(heading-bg, svg, center top, no-repeat, cover);
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 160px;
    padding-bottom: 90px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 185px;
  }

  &__holder {
    width: 100%;
    @include media-breakpoint-up(lg) {
      text-align: center;
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.6px;
      color: rgba($white, 0.5);
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &__down {
    position: absolute;
    right: 20px;
    bottom: -24px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    color: $cyan;
    border-radius: 50%;
    background: $white;
    box-shadow: 0 4px 4px rgba($black, 0.08);
    @include media-breakpoint-up(lg) {
      margin-left: -24px;
      right: auto;
      left: 50%;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__search {
    padding: 0 40px 0 20px;
    position: absolute;
    bottom: -40px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    @include media-breakpoint-up(lg) {
      padding-right: 20px;
      width: 550px;
    }
    @include media-breakpoint-up(xl) {
      bottom: -40px;
      width: 800px;
    }
  }

  &--search {
    padding-bottom: 60px;
    z-index: 4;
  }
}

// begin house filter
.page-widget {
  &:has(.house-filter) {
    margin-bottom: 50px;
    z-index: 3;

    @include media-breakpoint-up(lg) {
      margin-bottom: 45px;
    }

    @include media-breakpoint-up(xl) {
      padding: {
        top: 8px;
        bottom: 8px;
      }
      background-color: $light-alt;
      position: sticky;
      top: -1px;

      &.is-pinned {
        border-bottom: 1px solid $light;
      }
    }
  }
}

.house-filter, .account-filter {
  position: relative;
  width: 100%;

  &--shadow {
    margin-bottom: -25px;
    padding-bottom: 25px;
    z-index: 7;
    border-radius: 0 0 20px 20px;
    background: $white;
    box-shadow: 0 8px 8px rgba($black, 0.08);
  }

  &__left,
  &__list {
    flex-shrink: 0;
  }

  &__holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-up(xl) {
      flex-wrap: nowrap;
    }
  }

  &__btn {
    padding: 0 12px;
    display: inline-flex;
    align-items: center;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    color: $blue;
    border: 1px solid $blue;
    border-radius: 8px;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      color: $white;
      background: $blue;
    }

    i {
      margin-right: 10px;
      font-size: 12px;
    }
  }

  &__list {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(xl) {
      order: 2;
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;

      &.list {
        a.toggle {
          &::before {
            left: 2px;
          }
        }
      }

      &.map {
        a.toggle {
          &::before {
            right: 2px;
          }
        }
      }

      .toggle {
        height: 24px;
        width: 46px;
        background-color: $cyan;
        border-radius: 13px;
        position: relative;
        margin: 0 8px;
        display: block;
        cursor: pointer;

        &::before {
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $white;
          position: absolute;
          top: 2px;
        }
      }

      li {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: $blue;
          font-weight: 600;
          transition: .3s ease;

          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }

          .icon-map {
            font-size: 17px;
          }

          &:hover {
            text-decoration: none;
          }
        }

        &.is-active {
          a {
            color: $cyan;
          }
        }
      }
    }
  }

  &__left {
    position: relative;
    z-index: 2;
  }

  &__number {
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      margin-bottom: 0;
      padding-top: 10px;
      font-size: $font-size-lg;
      color: $blue;

      strong {
        font-weight: 700;
      }
    }
  }

  &__center {
    margin-top: 15px;
    padding-bottom: 10px;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 1 auto;
      margin-top: 0;
      margin-right: 24px;
      margin-left: 24px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      width: 100%;

      li {
        margin: 0 3px;

        &:first-child {
          margin-left: auto;
        }

        &:last-child {
          margin-right: auto;
        }

        a {
          padding: 0 5px;
          display: inline-block;
          font-size: 12px;
          font-weight: 300;
          white-space: nowrap;
          text-align: center;
          color: $blue;
          transition: all 0.3s;
          @include media-breakpoint-up(lg) {
            padding: 0 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            font-size: 14px;
            border: 1px solid $light-border;
            border-radius: 8px;
          }

          i {
            margin: 0 auto 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border: 1px solid $light-border;
            border-radius: 80px;
            transition: all 0.3s;
            @include media-breakpoint-up(lg) {
              margin: 0 10px 0 0;
              display: inline-block;
              width: auto;
              height: auto;
              border: 0;
              border-radius: 0;
            }
          }

          &:hover, &.is-active {
            text-decoration: none;
            color: $cyan;
            @include media-breakpoint-up(lg) {
              color: $blue;
              background-color: $light-border;
            }

            i {
              border-color: $cyan;
              @include media-breakpoint-up(lg) {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}
// end house filter

.house-heading {
  margin-bottom: 40px;
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  background-color: $blue;
  z-index: 4;
  @include background-image(house-heading-bg, svg, center top, no-repeat, cover);
  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    &:not(.house-heading__thin) {
      padding-top: 200px;
    }
  }

  &__thin {
    height: 140px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__search {
    margin: 0 auto;
    padding-right: 25px;
    max-width: 802px;
  }
}

.house-list {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  .project-banner {
    width: 100%;
    padding: 0 9px;

    .card-onboarding,
    .cta-banner {
      margin-top: 40px;
      @include media-breakpoint-up(xl) {
        margin-top: 60px;
      }
    }
  }

  &__top {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h6 {
      margin-bottom: 0;
    }
  }

  &__list {
    margin: 0 -8px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
  }

  &__list-item {
    padding: 9px 9px 25px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
    @include media-breakpoint-up(xl) {
      width: 33.333%;
    }

    .card-house__image {
      img {
        aspect-ratio: 3/2;
        object-fit: cover;
      }
    }
  }
}

.landing-banner {
  margin-bottom: 30px;
  padding-top: 160px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
    padding-top: 160px;
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 640px;
      border-radius: 0 0 16px 16px;
      background: $blue;
    }
  }

  &__content {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
    color: $white;
    background: $blue;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
      z-index: 6;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 280px;
      width: 40%;
    }

    &:after {
      position: absolute;
      top: -180px;
      left: -185px;
      z-index: -1;
      width: 370px;
      height: 700px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    p,
    h1 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
  }

  &__image {
    margin-top: -10px;
    position: relative;
    z-index: 5;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
      overflow: visible;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: -185px;
        width: 370px;
        height: 100%;
        transform: scaleX(-1);
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__search {
    margin: -28px 0 0 20px;
    padding: 20px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -40px;
      width: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: -480px;
      right: 50%;
    }
    @include media-breakpoint-up(xl) {
      margin-right: -580px;
      padding: 45px;
      width: 530px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -680px;
    }

    p {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__search-form {
    position: relative;
    width: 100%;

    input {
      padding: 0 60px 0 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid $border-color;
      border-radius: 4px;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      color: $white;
      border: 0;
      border-radius: 50%;
      background: $cyan;
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      color: $cyan;
    }
  }
}

.login-popup {
  width: 100%;

  @include media-breakpoint-only(md) {
    max-width: 420px;
  }

  .form-group-wrapper {
    .row {
      margin: {
        right: -4px;
        left: -4px;
      }

      [class^="col-"] {
        padding: {
          right: 4px;
          left: 4px;
        }
      }
    }
  }

  .form-group {
    .is-filled {
      top: -10px;
      font-size: 12px;
    }

    .toggle-password {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      opacity: 0.3;
      z-index: 1;
    }
  }

  &.full-page {
    .form-group {
      .toggle-password {
        top: 35%;
      }
    }
  }

  &__link {
    text-decoration: underline;
    color: $teal;
  }

  &__facebook {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    background: #1b76f2;
  }

  &__google {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border: 1px solid $light;
    border-radius: 4px;
  }

  &__apple {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    background: $gray-500;
  }

  &__of {
    margin: 30px 0;
    position: relative;
    width: 100%;
    text-align: center;
    // TODO: Remove when other login works
    display: none;

    span {
      padding: 0 30px;
      position: relative;
      z-index: 1;
      display: inline-block;
      background: $white;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: $gray-500;
      content: '';
    }
  }

  > .row {
    // TODO: Remove when other login works
    display: none;
  }

  &__bottom {
    margin: 30px -30px -30px;
    padding: 30px;
    border-radius: 0 0 4px 4px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin: 30px -80px -60px;
      padding: 30px 80px;
    }
  }

  &--register {
    padding: 30px;
    @include media-breakpoint-up(lg) {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      max-width: 1075px;

      .mfp-close {
        &:after {
          color: $white;
        }
      }
    }
  }

  .usp-info {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 682px;
    margin-bottom: 0;
  }

  &__heading {
    align-items: baseline;

    a {
      font-weight: 600;
      color: $cyan;
    }
  }

  &__left {
    @include media-breakpoint-up(lg) {
      padding: 60px 80px 40px;
      width: 50%;
    }
  }

  &__right {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      width: 50%;
    }
  }

  .newsletter-container {
    padding-left: 0;
    padding-right: 0;

    .form-block {
      padding: 0;
      background: none;
      border: 1px solid #F4EEEA;

      @include media-breakpoint-up(xxl) {
        .region-template.container {
          padding-left: 10px;
          padding-right: 10px;

          .subscription {
            background: none;
          }
        }
      }

      .subscription .toggle-btn.disabled {
        opacity: 1;
        background: none;
        border: 1px solid #EDED;
        height: 26px;
        pointer-events: auto !important;

        &::before {
          background-color: #00B0A7;
        }
      }

      .hidden {
        display: none;
      }

      .expand-municipalities-control {
        cursor: pointer;
      }

      .expand-municipalities {
        padding-top: 0;

        .sub-checkboxes {
          margin-left: 30px;
        }
      }

      i {
        margin-right: 20px;
        width: 20px;
        height: 20px;
        font-size: 8px;

        &.icon-chevron-right {
          font-size: 14px;
        }
      }
    }
  }
}

.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

.pagination {
  margin: 24px 0 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: 50px 0;
  }
  @include media-breakpoint-up(xl) {
    margin: 70px 0;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include media-breakpoint-up(lg) {
      justify-content: center;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    @include media-breakpoint-up(md) {
      padding: 0 80px;
    }
  }

  &__next,
  &__prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 10px;
    color: $white;
    border-radius: 50%;
    background: $blue;
    transition: all 0.3s;

    @include media-breakpoint-up(md) {
      width: 32px;
      height: 32px;
    }

    @include media-breakpoint-up(lg) {
      width: 48px;
      height: 48px;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
      background: $light-border;
    }
  }

  &__list-item {
    margin: 0 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      margin: 0 4px;
      height: 32px;
      width: 32px;
      color: $teal;
      border: 1px solid $light-border;
    }

    @include media-breakpoint-up(lg) {
      height: 48px;
      width: 48px;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      color: $primary;
      border-radius: 50%;
      @include media-breakpoint-up(md) {
        width: 32px;
        color: $blue;
        background: $light-border;
      }

      @include media-breakpoint-up(lg) {
        width: 48px;
        height: 48px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      transition: all 0.3s;
      @include media-breakpoint-up(md) {
        width: 32px;
        color: $teal;
      }

      @include media-breakpoint-up(lg) {
        width: 48px;
        height: 48px;
      }

      &:hover {
        color: $primary;
        @include media-breakpoint-up(md) {
          color: $blue;
          background: $light-border;
        }
      }
    }
  }
}

.project-slider {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  &--background {
    background-image: linear-gradient(to bottom, rgba(250, 244, 239, 1) 0%, rgba(255, 253, 249, 1) 100%);
    @include media-breakpoint-up(xl) {
      padding: {
        top: 130px;
        bottom: 30px;
      };
    }
    padding: {
      top: 60px;
      bottom: 20px;
    };
  }

  &--background2 {
    margin-bottom: 60px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
      padding: {
        top: 120px;
        bottom: 120px;
      };
    }
    padding: {
      top: 60px;
      bottom: 60px;
    };
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__top-left {
    display: inline-flex;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }
  }

  &__number {
    margin-right: 15px;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    color: $pink;
    border-radius: 40px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-right: 25px;
      padding: 0 25px;
      height: 48px;
    }
  }

  &__slider {
    position: relative;
    width: 100%;

    &:after {
      @include media-breakpoint-up(xxxl) {
        position: absolute;
        top: 0;
        left: 100%;
        width: 70000px;
        height: 100%;
        background: rgba($light-alt, 0.5);
        content: '';
      }
    }
  }

  &__slide {
    margin-right: 10px;
    width: 350px;
    @include media-breakpoint-up(xl) {
      margin-right: 16px;
      width: 528px;
    }

    .card-house {
      .card-house__bar .card-house__bar-inner {
        span {
          left: 0;
          border-radius: 0 4px 0 0;
        }
      }
    }
  }

  &__holder {
    position: relative;
    width: 100%;
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 115px;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      width: 40px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      border: 0;
      background: transparent;
      transition: all 0.3s;
      cursor: pointer;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }
  }

  &--step {
    z-index: 9;

    .project-slider__slider {
      &:after {
        display: none;
      }
    }
  }

  &__prev {
    padding-left: 12px;
    left: 0;

    &:after {
      border-color: transparent transparent transparent $white;
      border-width: 70px 0 70px 40px;
    }
  }

  &__next {
    padding-left: 15px;
    right: 0;

    &:after {
      border-color: transparent $white transparent transparent;
      border-width: 70px 40px 70px 0;
    }
  }

  &--inner {
    .card-house__list {
      padding: 8px 0;
    }
  }
}

.residential-account {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__account {
    margin-bottom: 15px;
    padding: 30px 20px;
    position: relative;
    border-radius: 16px;
    background: $gray-300;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 340px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 40px 60px;
    }
  }

  &__account-text {
    padding-left: 95px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding-left: 40%;
    }
  }

  &__account-image {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 100%;
    background: $light-border;
    clip-path: polygon(0 0, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 0 100%);
    @include media-breakpoint-up(lg) {
      width: 180px;
      clip-path: polygon(0 0, calc(100% - 90px) 0%, 100% 50%, calc(100% - 90px) 100%, 0 100%);
    }
    @include media-breakpoint-up(xl) {
      width: 210px;
    }
    @include media-breakpoint-up(xxl) {
      width: 240px;
    }
    @include media-breakpoint-up(xxxl) {
      width: 290px;
    }

    img {
      width: auto;
      max-height: 54px;
      @include media-breakpoint-up(lg) {
        max-height: 100px;
      }
      @include media-breakpoint-up(xxl) {
        max-height: 142px;
      }
    }
  }

  &__search {
    margin-bottom: 15px;
    padding: 30px 20px;
    position: relative;
    border-radius: 16px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 340px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 40px 60px;
    }

    h4 {
      color: $white;
    }
  }

  &__search-text {
    padding-left: 95px;
    width: 100%;
    color: rgba($white, 0.5);
    @include media-breakpoint-up(lg) {
      padding-left: 40%;
    }
  }

  &__search-image {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(22, 21, 73, 0.7) 0%, rgba(46, 45, 92, 0.7) 100%);
    clip-path: polygon(0 0, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 0 100%);
    @include media-breakpoint-up(lg) {
      width: 180px;
      clip-path: polygon(0 0, calc(100% - 90px) 0%, 100% 50%, calc(100% - 90px) 100%, 0 100%);
    }
    @include media-breakpoint-up(xl) {
      width: 210px;
    }
    @include media-breakpoint-up(xxl) {
      width: 240px;
    }
    @include media-breakpoint-up(xxxl) {
      width: 290px;
    }

    img {
      width: auto;
      max-height: 48px;
      @include media-breakpoint-up(lg) {
        max-height: 100px;
      }
      @include media-breakpoint-up(xxl) {
        max-height: 125px;
      }
    }
  }

  &--small {
    .residential-account__account-image,
    .residential-account__search-image {
      @include media-breakpoint-down(md) {
        margin: 0 auto 15px;
        position: static;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: $white;
        clip-path: none;
      }
    }

    .residential-account__account-text,
    .residential-account__search-text {
      @include media-breakpoint-down(md) {
        padding: 0;
        text-align: center;
      }
    }

    .residential-account__account,
    .residential-account__search {
      @include media-breakpoint-down(md) {
        display: block;
        background: $gray-300;
      }

      h4 {
        @include media-breakpoint-down(md) {
          font-size: 18px;
          color: $blue;
        }
      }
    }
  }
}

.seo-text {
  position: relative;
  width: 100%;
  font-size: $font-size-base;
  @include media-breakpoint-up(md) {
    font-size: $font-size-lg;
  }
  @include media-breakpoint-up(lg) {
    border-top: 1px solid $border-color;
    padding: {
      top: 60px;
    }
    margin: {
      top: 60px;
    }
  }
  @include media-breakpoint-up(xl) {
    padding: {
      top: 120px;
    }
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  padding: {
    top: 30px;
  }
  margin: {
    bottom: 60px;
  }

  h3 {
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    strong {
      color: $pink;
    }
  }

  p {
    margin-bottom: 30px;
    color: $blue;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
      color: $pink;
    }
  }

  &__list {
    margin: 0 -8px;
    padding: 15px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 16px);
    @include media-breakpoint-up(xl) {
      padding-top: 30px;
    }
  }

  &__list-item {
    padding: 0 8px 8px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 16px;
      width: 33.333%;
    }
  }

  &__list-link {
    padding: 30px 60px 30px 50px;
    position: relative;
    display: block;
    width: 100%;
    color: $blue;
    border-radius: 8px;
    background: $gray-300;
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      padding: 30px 80px;
    }

    &:after {
      margin-top: -24px;
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 2;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      font-family: $font-family-icon;
      font-size: 14px;
      font-weight: 400;
      color: $blue;
      border-radius: 50%;
      background: $white;
      transition: all 0.3s;
      content: '\e904';
      @include media-breakpoint-up(xl) {
        right: 20px;
      }
    }

    i {
      position: absolute;
      top: 50%;
      left: 20px;
      font-size: 20px;
      transform: translateY(-50%);
      transition: all 0.3s;
      @include media-breakpoint-up(xl) {
        left: 40px;
      }
    }

    &:hover {
      text-decoration: none;
      color: $blue;

      i {
        color: $cyan;
      }

      &:after {
        color: $white;
        background: $cyan;
      }
    }
  }
}

.share-popup {
  width: 100%;
}

#appleid-signin {
  width: 290px;
  height: 40px;
}

.sort-popup {
  width: 100%;
}

.usp-info {
  margin-bottom: 60px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: rgba($white, 0.8);
  border-radius: 8px;
  background-color: $blue;
  @include background-image(usp-info-bg2, svg, center center, no-repeat, cover);
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
    padding: 60px 25px;
    font-size: 16px;
  }

  h4 {
    margin-bottom: 20px;
    color: $white;
  }

  &__slider {
    position: relative;
    width: 100%;

    .slick-dots {
      margin: 0;
      padding: 0 0 40px;
      display: flex;
      justify-content: center;
      width: 100%;
      @include media-breakpoint-up(xl) {
        padding-bottom: 60px;
      }

      li {
        margin: 0 4px;
        display: block;
        width: 10px;
        height: 10px;

        button {
          padding: 0;
          display: block;
          width: 10px;
          height: 10px;
          text-indent: -9999px;
          border: 0;
          border-radius: 50%;
          background: $pink;
          cursor: pointer;
        }

        &.slick-active {
          button {
            background: $white;
          }
        }
      }
    }
  }

  &__image {
    margin: 0 auto 20px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 80px;
    @include background-image(usp-info-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
      width: 155px;
      height: 120px;
    }

    img {
      max-height: 50px;
      @include media-breakpoint-up(xl) {
        max-height: 76px;
      }
    }
  }
}

// Styling for the step option card
.card-assistance {
  margin-bottom: 8px;
  padding: 16px 20px;
  display: flex;
  width: 100%;
  text-align: center;
  border: 2px solid $white;
  border-radius: 16px;
  background: $white;
  min-height: 80px;
  transition: all 0.3s;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba($orange, .3);
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: .3s ease;

  }

  .card-assistance__icon,
  h5,
  span {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    display: block;
    padding: 0 0 24px;
    margin-bottom: 25px;
  }

  &:hover {
    text-decoration: none;
    border-color: $orange;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    transform: scale(1.1);
    z-index: 2;
    position: relative;

    &::before {
      transform: translateX(0);
    }
  }

  h5 {
    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
      margin-top: 12px;
      font-size: 18px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 21px;
    }
  }

  &--title {

    @include media-breakpoint-up(md) {
      padding-top: 32px;
      padding-bottom: 40px;
    }
  }

  &__icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: fit-content;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
      padding-top: 0 !important;
      width: 32px;
      height: auto !important;
    }

    @include media-breakpoint-up(md) {
      padding-top: 20px;
      height: 80px;
      width: 100%;
    }
  }

  .btn {
    transition: .3se ease;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 28px;
    }
  }
}

// Active state for the step option card
.step-option-button {
  @include media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
  }

  span {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  i {
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  i.icon-cross {
    transform: rotate(45deg);
  }

  i.icon-check {
    display: none;
  }
}

input.step-option-input:checked + label {
  text-decoration: none;
  border-color: $orange;
  box-shadow: 0 16px 48px rgba($black, 0.12);

  &::before {
    transform: translateX(0);
  }

  .step-option-button {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    i.icon-cross {
      display: none;
    }

    i.icon-check {
      display: inline;
    }
  }
}

.card-postcode {
  margin-bottom: 30px;
  padding: 20px 35px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    padding: 10px;
  }

  &__content {
    padding: 15px 0;
    @include media-breakpoint-up(lg) {
      padding: 35px 10px;
    }
  }

  &__map {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h6 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
  }
}

// begin livingwish popup
.onboarding-popup {
  width: 100%;
}

.housingneeds-popup {
  .mfp-close {
    display: none;
  }
}

.mfp-fullscreen {
  .mfp-container {
    padding: 0; /* 1 */
  }
  .mfp-content {
    height: 100%; /* 2 */
  }
}

.full-popup {
  background: #FFF;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
}
// end livingwish popup

.step-heading {
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
    padding-bottom: 60px;
  }

  @include media-breakpoint-up(xxxl) {
    padding-top: 200px;
  }

  h2 {
    color: $white;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 160px);
    }
  }
}

.step-pagination {
  margin: -20px 0 30px 0;
  position: relative;
  width: 100%;
  bottom: 0;

  @include media-breakpoint-up(xl) {
    margin: -100px 0 50px;
  }

  .btn {
    margin: 0 4px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      height: 48px;
      width: 48px;
      color: $teal;
    }

    &:after, &::before {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      transition: .3s ease;
      content: '';
    }

    &:after {
      background: rgba(248, 241, 234, 1);
    }

    &::before {
      width: 0;
      transition: .5s ease;
      transition-delay: .5s;
      z-index: 1;
      background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      position: relative;
      overflow: hidden;
      transition: .3s ease;

      @include media-breakpoint-up(md) {
        width: 48px;
        height: 48px;
      }

      &::before {
        content: '';
        background: $cyan;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: .3s ease;
        transition-delay: .3s;
        width: 100%;
        height: 100%;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        background-color: $gray-300 !important;
        span {
          color: $white;
        }

        &::before {
          transform: translateX(0);
        }
      }

      &:not(:last-child)::before {
        width: 16px;
      }

      &:not(:last-child)::after {
        background: rgba(248, 241, 234, 1) !important;
      }
    }

    &.is-done {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-color: $cyan;
      }
    }

    &.is-disabled {
      a {
        color: $white;
        background: $gray-500;
        cursor: default;
      }
    }

    &.is-blocked {
      display: none;
    }

    &.is-blocked.is-done {
      a {
        color: $white;
        background: $cyan;
      }
    }
  }
}

// begin step-section
.livingwish-popup {

  .hidden {
    display: none;
  }

  .wrapper {
    overflow: scroll;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 32px;
    }
  }
}

.step {
  display: none;
  transition: .3s ease;
  width: 100%;
  height: 100%;

  &:not(.hidden) {
    display: block;

    .step-pagination {
      li.is-active {
        a {
          &::before {
            transform: translateX(0);
          }
        }

        &::before {
          width: 16px;
        }
      }
    }

    .step-section {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .step-section {
    margin-bottom: 60px;
    position: relative;
    z-index: 6;
    width: 100%;
    // inactive
    opacity: 0;
    transform: translateX(-16px);
    transition: .3s ease;

    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
    }

    &__list {
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 16px);
    }

    &__list-item {
      padding: 0 8px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }
      @include media-breakpoint-up(xl) {
        width: 25%;
      }
      @include media-breakpoint-up(xxl) {
        width: 20%;
      }
    }

    .col-lg-3 {
      &:nth-child(1) {
        .step-image { // Hypotheek afsluiten
          background-image: url('../../assets/images/services-hypotheek-afsluiten.png');
        }
      }

      &:nth-child(2) {
        .step-image { // Verzekeringen
          background-image: url('../../assets/images/services-verzekeringen.png');
        }
      }

      &:nth-child(3) {
        .step-image { // Taxatie
          background-image: url('../../assets/images/services-taxatie.png');
        }
      }

      &:nth-child(4) {
        .step-image { // Verkopen
          background-image: url('../../assets/images/services-verkopen.png');
        }
      }

      &:nth-child(5) {
        .step-image { // Kopen
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }

      &:nth-child(6) {
        .step-image { // Huren
          background-image: url('../../assets/images/services-huren.png');
        }
      }

      :nth-child(7) {
        .step-image { // Makelaar
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }
    }
  }
}

.step-section__select {
  border-radius: 16px;
  background-color: $white;
  padding: 32px;
  text-align: center;
  box-shadow: 0 8px 8px 0 rgba($black, .08);

  @include media-breakpoint-up(xl) {
    padding: 64px 0 32px;
    max-width: 800px;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
  }

  .select-wrapper {
    margin: 24px auto 16px;
    max-width: 530px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
      margin: 40px auto 32px;
    }

    select {
      @include media-breakpoint-up(sm) {
        width: calc(50% - 8px);
      }

      &:first-child {
        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: fit-content;
    }
  }
}
// end step-section

.page-widget {
  .card-social {
    &__nav {
      .navigate-page {
        &__anchor {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
        }
      }
    }
  }
}

.realtor-popup {
  display: flex;
  gap: 40px;

  &__figure {
    overflow: hidden;
    flex: 1 0 180px;
    max-width: 180px;
    height: 310px;
    max-height: 100%;
    border-radius: 4px;
    background-color: $primary;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    @include media-breakpoint-down(md) {
      flex-basis: 100%;
      max-width: 100%;
      height: 160px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__header {
      h4 {
        margin-bottom: 8px;
      }

      address {
        display: block;
        margin-bottom: 0;
        padding-left: 20px;
        position: relative;
        font-size: 14px;
        font-weight: 300;
        color: $blue;

        @include media-breakpoint-up(lg) {
          padding-left: 25px;
          font-size: 16px;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          font-family: $font-family-icon;
          color: $teal;
          content: '\e900';
        }

        span {
          color: rgba($blue, 40%);
        }
      }
    }

    &__body {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .realtor-contact-info {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $blue;

    i {
      width: 20px;
      text-align: center;
      color: $cyan;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    max-width: 680px;
    padding: {
      top: 48px;
      bottom: 48px;
      left: 40px;
    }
  }
}

.region_intro {
  h2 {
    font-size: 1.5rem;
    line-height: 40px;
  }
}