// Table of Content
// ===============================================
// # Variables
// # Font */

// # Variables
// =============================================== */
$icons-code: 'utzjge';
$icons-font: 'nwn-icons';

// # Font
// =============================================== */

@font-face {
	font-family: $icons-font;
	src:
	url('#{$fonts-path}/nwn-icons/fonts/nwn-icons.ttf?#{$icons-code}') format('truetype'),
	url('#{$fonts-path}/nwn-icons/fonts/nwn-icons.woff?#{$icons-code}') format('woff'),
	url('#{$fonts-path}/nwn-icons/fonts/nwn-icons.svg?#{$icons-code}#nwn-icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: $icons-font !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
	content: "\e900";
}

.icon-apple:before {
	content: "\e901";
}

.icon-arrow-down:before {
	content: "\e902";
}

.icon-arrow-left:before {
	content: "\e903";
}

.icon-arrow-right:before {
	content: "\e904";
}

.icon-arrow-up:before {
	content: "\e905";
}

.icon-baby:before {
	content: "\e906";
}

.icon-bar-graph:before {
	content: "\e93f";
}

.icon-bell:before {
	content: "\e938";
}

.icon-calculator:before {
	content: "\e907";
}

.icon-chat:before {
	content: "\e908";
}

.icon-check:before {
	content: "\e909";
}

.icon-checklist:before {
	content: "\e941";
}

.icon-check-circle:before {
	content: "\e940";
}

.icon-chevron-down:before {
	content: "\e90a";
}

.icon-chevron-right:before {
	content: "\e90b";
}

.icon-chevron-up:before {
	content: "\e90c";
}

.icon-city:before {
	content: "\e90d";
}

.icon-cog:before {
	content: "\e939";
}

.icon-cross:before {
	content: "\e90e";
}

.icon-data:before {
	content: "\e942";
}

.icon-edit:before {
	content: "\e90f";
}

.icon-eye:before {
	content: "\e910";
}

.icon-facebook:before {
	content: "\e911";
}

.icon-flag:before {
	content: "\e912";
}

.icon-flowchart:before {
	content: "\e943";
}

.icon-forrest:before {
	content: "\e913";
}

.icon-grid:before {
	content: "\e918";
}

.icon-heart-alt:before {
	content: "\e919";
}

.icon-heart:before {
	content: "\e91a";
}

.icon-house:before {
	content: "\e91b";
}

.icon-houses:before {
	content: "\e944";
}

.icon-icon:before {
	content: "\e91c";
}

.icon-info:before {
	content: "\e91d";
}

.icon-instagram:before {
	content: "\e91e";
}

.icon-linkedin:before {
	content: "\e91f";
}

.icon-list:before {
	content: "\e920";
}

.icon-location:before {
	content: "\e921";
}

.icon-logout:before {
	content: "\e922";
}

.icon-mail:before {
	content: "\e923";
}

.icon-map:before {
	content: "\e924";
}

.icon-marker:before {
	content: "\e93a";
}

.icon-megaphone:before {
	content: "\e945";
}

.icon-menu:before {
	content: "\e925";
}

.icon-minus:before {
	content: "\e926";
}

.icon-overview:before {
	content: "\e93b";
}

.icon-person:before {
	content: "\e93c";
}

.icon-phone:before {
	content: "\e927";
}

.icon-plus:before {
	content: "\e928";
}

.icon-projects:before {
	content: "\e93d";
}

.icon-question:before {
	content: "\e929";
}

.icon-quote:before {
	content: "\e92a";
}

.icon-room:before {
	content: "\e92b";
}

.icon-search:before {
	content: "\e92c";
}

.icon-send:before {
	content: "\e92d";
}

.icon-share:before {
	content: "\e92e";
}

.icon-shop:before {
	content: "\e946";
}

.icon-sort:before {
	content: "\e92f";
}

.icon-special:before {
	content: "\e930";
}

.icon-speechbubble:before {
	content: "\e93e";
}

.icon-time:before {
	content: "\e931";
}

.icon-twitter:before {
	content: "\e932";
}

.icon-video:before {
	content: "\e933";
}

.icon-water:before {
	content: "\e934";
}

.icon-whatsapp:before {
	content: "\e935";
}

.icon-world:before {
	content: "\e936";
}

.icon-youtube:before {
	content: "\e937";
}
