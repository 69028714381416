@import '~assets/styles/variables';

.card-social {
	margin-bottom: 18px;
	padding: 20px 30px 30px;
	position: relative;
	z-index: 1;
	width: 100%;
	border-radius: 8px;
	background: $gray-300;

	&__title {
		margin: -20px -30px 25px;
		padding: 20px 30px;
		width: calc(100% + 60px);
		border-radius: 8px 8px 0 0;
		background: $blue;

		h6 {
			margin-bottom: 0;
			font-weight: 600;
			color: $white;
		}
	}

	&__social {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;

		strong {
			margin-right: 22px;
			margin-bottom: 10px;
			width: 100%;
			font-weight: 600;
			color: $blue;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
				width: auto;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			display: inline-flex;
			flex-wrap: wrap;

			li {
				margin-left: 8px;
        margin-bottom: 8px;

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 48px;
					height: 48px;
					color: $blue;
					border-radius: 50%;
					background: $white;
					transition: all 0.3s;
					box-shadow: 0 8px 8px rgba($black, 0.08);

					&:hover {
						text-decoration: none;
						color: $white;
						background: $blue;
					}
				}
			}
		}
	}

	&__user {
		margin-bottom: 40px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
	}

	&__user-img {
		width: 48px;
		height: 48px;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	&__user-text {
		padding-left: 15px;
		width: calc(100% - 48px);

		span {
			display: block;
			font-size: 14px;
			font-weight: 300;
		}

		strong {
			display: block;
			font-size: 16px;
			color: $blue;
		}
	}

  &__nav {
    display: flex;
    flex-direction: column;

    .navigate-page {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;

      @include media-breakpoint-up(xxxl) {
        gap: unset;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
        border-bottom: 1px solid rgba($blue, 10%);
        padding-bottom: 24px;
      }

      &--next {
        .navigate-page__figure {
          @include media-breakpoint-up(xxxl) {
            margin-right: 20px;
          }
        }

        i {
          margin-left: auto;
        }
      }

      &--previous {
        .navigate-page__figure {
          @include media-breakpoint-up(xxxl) {
            margin-left: 20px;
          }
        }

        i {
          margin-right: auto;
        }
      }

      &__figure {
        overflow: hidden;
        display: block;
        flex: 1 0 64px;
        max-width: 64px;
        height: 64px;
        border-radius: 8px;
        background-color: $blue;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }

        @include media-breakpoint-only(lg) {
          display: none;
        }

        @include media-breakpoint-up(xxxl) {
          flex-basis: 80px;
          max-width: 80px;
        }
      }

      &__content {
        font-size: 16px;

        > span {
          display: block;
          font-weight: 300;
        }

        p {
          display: block;
          max-height: none;
          font-weight: 600;
          color: $blue;
        }
      }

      i {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 32px;
        max-width: 32px;
        height: 32px;
        font-size: 13px;
        color: $cyan;

        @include media-breakpoint-up(xxxl) {
          flex-basis: 56px;
          max-width: 56px;
          height: 56px;
        }
      }
    }
  }
}
